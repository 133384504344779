export const visa_card_icon = require("../assets/visa_card_icon.png");
export const master_card_icon = require("../assets/master_card_icon.png");
export const visa_dark_card_icon = require("../assets/visa_dark_icon.png");
export const success_icon = require("../assets/success-icon.png");
export const edit_icon = require("../assets/edit-icon.png");
export const delete_icon = require("../assets/delete-icon.png");
export const visa_icon = require("../assets/visa_icon.png");
export const edit_light_icon = require("../assets/edit_light_icon.png");
export const payment_success = require("../assets/payment-success.png");
export const payment_failed = require("../assets/payment-failed.png");
export const upload_success = require("../assets/upload_success.png");

