import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, cardIcon, deleteIcon, dollarIcon, historyIcon, lockIcon, logoutIcon, personIcon } from "./assets";
import { GenericApiCall } from "../../utilities/src/GenericApiCall";
import { lowerCase, capitalCase, numberCase, getToken, specialChar } from "../../utilities/src/RegExRequired";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  specialOffers: boolean;
  weeklyUpdates: boolean;
  showAlert: boolean;
  alertData: {
    title: string,
    text: string,
    buttons: { name: string, type: "text" | "outlined" | "contained" | undefined, action: string, class: string }[]
  };
  errMessage: {
    type: "error" | "warning" | "info" | "success" | "",
    msg: string
  },
  showErrMsg: boolean;
  accountsList: any;
  orderList: any;
  notificationSettingsList: any;
  accountActionList: any;
  oldPassWord: string;
  newPassword: string;
  changePasswordErr: string;
  confirmNewPassword: string;
  showOldPassword: boolean,
  showNewPassword: boolean,
  showConfirmPassword: boolean,
  passwordErrors: any[],
  isNewPasswordValid: boolean,
  showNewPasswordValidation: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  logoutApiCallId: string = '';
  deleteAccountApiCallId: string = '';
  updateNotificationsApiCallId: string = '';
  getNotficationDataApiCallId: string = '';
  errorMsgInterval: any;
  changePasswordApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      weeklyUpdates: false,
      specialOffers: false,
      showAlert: false,
      alertData: {
        title: '',
        text: '',
        buttons: []
      },
      errMessage: {
        type: '',
        msg: ''
      },
      showErrMsg: false,
      accountActionList: configJSON.accountActions,
      notificationSettingsList: configJSON.notifications,
      accountsList: configJSON.accountList,
      orderList: configJSON.orderDetails,
      newPassword: '',
      oldPassWord: '',
      changePasswordErr: '',
      confirmNewPassword: '',
      showConfirmPassword: false,
      showNewPassword: false,
      showOldPassword: false,
      passwordErrors: [
        { error: 'number', msg: 'At least one number' },
        { error: 'lower', msg: 'At least one lowercase letter' },
        { error: 'length', msg: 'Minimum character length is 8 characters' },
        { error: 'capital', msg: 'At least one capital letter' },

      ],
      isNewPasswordValid: false,
      showNewPasswordValidation: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getNotificationsValue();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let data = message.getData("pageName");
      this.setState({ txtSavedValue: data })
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (apiRequestCallId != null) {

        if (apiRequestCallId == this.logoutApiCallId) {
          this.handleLogoutResponse(responseJson)
        }
        else if (apiRequestCallId == this.deleteAccountApiCallId) {
          this.handleDeleteAccountResponse(responseJson)
        }
        else if (apiRequestCallId == this.getNotficationDataApiCallId) {
          this.handleNotificationValues(responseJson)
        }
        else if (apiRequestCallId == this.changePasswordApiCallId) {
          this.handleUpdatePasswordResponse(responseJson)
        }
      }
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  setNewPassword = (newPassword: string) => {
    this.setState({ newPassword })
    this.setState({ isNewPasswordValid: this.checkForPasswordErrors('default', newPassword) })
    this.state.passwordErrors.forEach((error: any) => {
      error.isValid = this.checkForPasswordErrors(error.error, newPassword);
    })
  }
  setoldPassWord = (oldPassWord: string) => {
    this.setState({ oldPassWord })
  }
  setConfirmPassword = (confirmNewPassword: string) => {
    this.setState({ confirmNewPassword })
  }
  updatePasswordVisibility = (password: string) => {
    switch (password) {
      case 'old':
        this.setState({ showOldPassword: !this.state.showOldPassword })
        break;
      case 'new':
        this.setState({ showNewPassword: !this.state.showNewPassword })
        break;
      case 'confirm':
        this.setState({ showConfirmPassword: !this.state.showConfirmPassword })
        break;
    }
  }
  handleLogoutResponse = (responseJson: any) => {
    if (responseJson?.errors) {
      this.handleErrorResponse(responseJson)
    }
    else {
      let userData: any = localStorage.getItem("userData")
      userData = userData ? JSON.parse(userData) : null;
    
      if (userData && !userData?.isRemember) {
        localStorage.clear();
      } else if (userData) {
        localStorage.removeItem("token");
      }
      window.location.href = "/EmailAccountLoginBlock";

    }
  }
  handleDeleteAccountResponse = (responseJson: any) => {
    if (responseJson?.errors) {
      this.handleErrorResponse(responseJson)
    }
    else {
      localStorage.clear()
      localStorage.setItem('acc_Status', 'true')
      window.location.replace("/EmailAccountLoginBlock");
      window.history.pushState(null, '', '/dummypage')
      window.history.replaceState(null, '', '/EmailAccountLoginBlock')
      window.history.replaceState(null, '', '/EmailAccountLoginBlock?updated=true')
      window.history.replaceState(null, '', '/EmailAccountLoginBlock?cache=' + Date.now());
      window.history.pushState(null, '', '/EmailAccountLoginBlock')
      window.history.pushState(null, '', '/EmailAccountLoginBlock?updated=true')
      window.history.pushState(null, '', '/EmailAccountLoginBlock?cache=' + Date.now());
    }
  }
  handleNotificationValues = (responseJson: any) => {
    if (responseJson?.errors) {
      this.handleErrorResponse(responseJson)
    } else {
      let userData = responseJson.data.attributes;
      this.setState({
        weeklyUpdates: userData.weekly_updates,
        specialOffers: userData.special_offers
      });
    }
  }
  handleUpdatePasswordResponse = (responseJson: any) => {
    if (responseJson?.errors) {
      let msgs: string[] = Object.values(responseJson.errors[0]);
      this.setState({ changePasswordErr: msgs[0] })
    } else {
      localStorage.setItem("showPasswordSuccess", JSON.stringify(true))
      this.navigateBack();
    }
  }
  handleErrorResponse = (responseJson: any) => {
    let msgs: string[] = Object.values(responseJson.errors[0]);
    this.setState({
      errMessage: { type: 'error', msg: msgs[0] },
      showErrMsg: true
    });

    if (this.errorMsgInterval) {
      clearInterval(this.errorMsgInterval);
    }

    this.errorMsgInterval = setTimeout(() => {
      this.setState({ showErrMsg: false });

    }, 5000);
  }
  manageAlertPopUp = (action: string) => {
    if (action == 'delete') {
      this.setState({ showAlert: true, alertData: configJSON.deleteAlert })
    }
    else if (action == 'logout') {
      this.setState({ showAlert: true, alertData: configJSON.logoutAlert })
    }
    else if (action == 'update') {
      this.setState({ showAlert: true, alertData: configJSON.weeklyUpdates })
    }
    else if (action == 'offer') {
      this.setState({ showAlert: true, alertData: configJSON.specialOffers })
    }
  }
  closeAlertPopUp = () => {
    this.setState({ showAlert: false })
  }
  performActionOnAccount = (action: string) => {
    this.setState({ showAlert: false })
    if (action == 'delete') {
      this.deleteAccount()
    } else if (action == 'logout') {
      this.logoutAccount()
    } else if (action == 'cancel') {
      this.closeAlertPopUp()
    }
    else {
      this.switchValue(action)
    }
  }
  deleteAccount = () => {
    let token = JSON.parse(JSON.stringify(localStorage.getItem('token')));
    let requestMesg = GenericApiCall(configJSON.validationApiContentType, configJSON.deleteApiMethod, configJSON.deleteApiEndPoint, token ? token : '')
    this.deleteAccountApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }
  logoutAccount = () => {
    let token = JSON.parse(JSON.stringify(localStorage.getItem('token')));
    this.sendUpdateRequest()
    let requestMesg = GenericApiCall(configJSON.validationApiContentType, configJSON.deleteApiMethod, configJSON.logoutApiEndPoint, token)
    this.logoutApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)

  }
  switchValue = (value: string) => {
    value === 'update' ? this.setState({ weeklyUpdates: !this.state.weeklyUpdates }) : this.setState({ specialOffers: !this.state.specialOffers })
  }
  gotoPage = (pageName: string, value?: string) => {
    if (pageName) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      const raiseMsg = new Message(getName(MessageEnum.NavigationPayLoadMessage));
      raiseMsg.addData("pageName", value)
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msg.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMsg)
      this.send(msg);
    }
  }

  updateNotifications = (endPoint: string, body: string) => {
    let token = JSON.parse(JSON.stringify(localStorage.getItem('token')));
    let requestMesg = GenericApiCall(configJSON.validationApiContentType, configJSON.patchApiMethod, endPoint, token, body)
    this.updateNotificationsApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }
  sendUpdateRequest = () => {
    let specialOffer = {
      "notifications": {
        "special_offers": this.state.specialOffers
      }
    }
    let weeklyUpdate = {
      "notifications": {
        "weekly_updates": this.state.weeklyUpdates
      }
    }
    this.updateNotifications(configJSON.specialOffersApiEndPoint, JSON.stringify(specialOffer))
    this.updateNotifications(configJSON.weeklyUpdatesApiEndPoint, JSON.stringify(weeklyUpdate))
  }
  async componentDidMount() {
    super.componentDidMount()
    window.addEventListener("pageshow", this.handlePageShow);
    let token = getToken();
    if (!token) {
      const msg: Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
    else {
      let passwordSuccess = localStorage.getItem("showPasswordSuccess")
      let profileUpdateSuccess = localStorage.getItem("showProfileUpdateSuccess")
      if (passwordSuccess) {
        let msg: string = 'Password successfully changed';

        this.setState({
          errMessage: { type: 'success', msg: msg },
          showErrMsg: true
        });

    
        this.errorMsgInterval = setTimeout(() => {
          this.setState({ showErrMsg: false });
          localStorage.removeItem("showPasswordSuccess")
        }, 5000);
      }
      if(profileUpdateSuccess && JSON.parse(profileUpdateSuccess)){
        let msg: string = 'Profile successfully updated';

        this.setState({
          errMessage: { type: 'success', msg: msg },
          showErrMsg: true
        });

        this.errorMsgInterval = setTimeout(() => {
          this.setState({ showErrMsg: false, errMessage: { msg: "", type: "" } });
          localStorage.removeItem("showProfileUpdateSuccess")
        }, 5000);
      }
    }
  }
  async componentWillUnmount() {
    this.sendUpdateRequest()
    window.removeEventListener("pageshow", this.handlePageShow);
  }

  handlePageShow = (event: PageTransitionEvent) => {
    let token = getToken();

    if (event.persisted && !token) {
      window.location.replace("/EmailAccountLoginBlock");
    }
  };

  getNotificationsValue = () => {
    let token = JSON.parse(JSON.stringify(localStorage.getItem('token')));
    let requestMesg = GenericApiCall(configJSON.validationApiContentType, configJSON.validationApiMethodType, configJSON.notificationsDataApiEndPoint, token)
    this.getNotficationDataApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }
  getIconForMenu = (menuTitle: string) => {
    switch (menuTitle) {
      case 'card':
        return cardIcon;
      case 'dollar':
        return dollarIcon;
      case 'person':
        return personIcon;
      case 'lock':
        return lockIcon;
      case 'history':
        return historyIcon
      case 'logout':
        return logoutIcon
      case 'delete':
        return deleteIcon
    }
  }
  sendPasswordChangeRequest = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    if (!this.state.newPassword || !this.state.oldPassWord || !this.state.confirmNewPassword) {
      this.setState({ changePasswordErr: 'All fields are mandatory' })
    }
    else if (this.state.isNewPasswordValid && !specialChar.test(this.state.newPassword)) {
      this.setState({ changePasswordErr: `Following chars not alloewd: ~+-=<>,?{}[]:;  ,' '` })
    }
    else if (this.state.newPassword === this.state.oldPassWord) {
      this.setState({ changePasswordErr: 'New password cannot be same as old password' })
    }
    else if (this.state.newPassword != this.state.confirmNewPassword) {
      this.setState({ changePasswordErr: 'New password & confirm password should match' })
    } else if (this.state.newPassword.length>20){
      this.setState({changePasswordErr:'Password must include at least one number, one uppercase letter, one lowercase letter. Password must be between 8 and 20 characters'})
    }

    else if (this.state.isNewPasswordValid) {
      let httpBody = {
        "data": {
          "type": "email_account",
          "current_password": this.state.oldPassWord,
          "new_password": this.state.newPassword
        }
      }
      this.setState({ changePasswordErr: '' })
      let token = JSON.parse(JSON.stringify(localStorage.getItem('token')));
      let requestMesg = GenericApiCall(configJSON.validationApiContentType, configJSON.patchApiMethod, configJSON.updatedPasswordApiEndPoint, token, JSON.stringify(httpBody))
      this.changePasswordApiCallId = requestMesg.messageId;
      runEngine.sendMessage(requestMesg.id, requestMesg)
    }
  }

  checkForPasswordErrors = (typeOfError: string, password: string) => {
    switch (typeOfError) {
      case 'lower':

        return lowerCase.test(password)
      case 'length':
        return password.length > 7

      case 'number':
        return numberCase.test(password)
      case 'capital':

        return capitalCase.test(password)

      default:
        if (numberCase.test(password) && lowerCase.test(password) && capitalCase.test(password) && password.length > 7) {
          return true
        }
        else {
          return false
        }
    }
  }
  validateNewPassword = (action: string) => {
    setTimeout(() => {
      if (action == 'blur' && this.state.newPassword.length == 0) {
        this.setState({ showNewPasswordValidation: false })
      } else if (action == 'focus') {
        this.setState({ showNewPasswordValidation: true })
      } else {
        this.setState({ showNewPasswordValidation: true })
      }
    }, 200)
  }
  navigateBack = () => {
    this.props.navigation.goBack()
  }
  // Customizable Area End
}
