Object.defineProperty(exports, "__esModule", {
    value: true,
  });
  
  // Customizable Area Start
  exports.apiContentType = "application/json";
  exports.apiEncodedContentPath = "application/x-www-form-urlencoded";
  exports.validationApiContentType = "application/json";
  exports.apiMethodType = "POST";
  exports.confirmPaymentMethod = "POST";
  exports.getPaymentMethod = "GET";  // gett api
  exports.apiStripeToken = "POST"; //  generate id for add method
  exports.validationApiMethodType = "GET";
  exports.stripePaymentIntent = "stripe_integration/payment_intents"
  exports.SavedAccountEndPoint ='/bx_block_stripe_integration/payment_methods';
  exports.PaymentIntentEndPoint ='/bx_block_stripe_integration/payments';
  exports.PaymentMethodStripeKeyEndPoint ='/bx_block_stripe_integration/payment_methods/stripe_keys';
  exports.PaymentSuccessEndPoint ='/bx_block_stripe_integration/payments/payment_success';
  exports.getScreensApiEndPoint = 'bx_block_custom_form/screens/'
  
  exports.stripePublishableKey = ""
  exports.stripeMerchantDisplayName = "Example.com"
  exports.stripeMerchantIdentifier = "stripeMerchantIdentifier" //used for apple pay
  exports.urlScheme = "rnbuildingblockmasterapp" //app deep linking url
  exports.getCardDetailsEndPoints = 'bx_block_stripe_integration/payment_methods';
  exports.postStripeToken = 'https://api.stripe.com/v1/tokens';
  exports.cardDetailsContentType = 'application/json';
  // Customizable Area End
  