import { getToken } from "../../utilities/src/RegExRequired";
let config = require("../../../framework/src/config");

export const fetchUserStatus = (setBlocked: (blocked: boolean) => void) => {
  if (!setBlocked) return;

  let urlObserver: MutationObserver | null = null; // ✅ Explicitly typed

  const fetchSingleUserStatus = async () => {
    try {
      let fullURL = config.baseURL + "/account_block/accounts/specific_account";

      if (getToken()) {
        const response = await fetch(fullURL, { headers: { token: getToken() || "" } });
        const userDataValue = await response.json();
        const isBlocked = userDataValue?.data?.attributes?.status === "blocked" || userDataValue?.data.attributes?.activated === false;
        let accountStatus = localStorage.getItem('acc_Status')
        setBlocked(isBlocked);
        if (isBlocked || accountStatus) {
          localStorage.clear();
          document.location.pathname = "/EmailAccountLoginBlock";
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.error("Error fetching user status:", error);
    }
  };

  // Initial check
  fetchSingleUserStatus();

  // Observe URL changes
  let lastUrl = document.location.pathname;
  urlObserver = new MutationObserver(() => {
    if (document.location.pathname !== lastUrl) {
      lastUrl = document.location.pathname;
      fetchSingleUserStatus();
    }
  });

  urlObserver.observe(document.body, { childList: true, subtree: true });

  // ✅ Return a cleanup function
  return () => {
    if (urlObserver) {
      urlObserver.disconnect();
      urlObserver = null; // ✅ Reset to null to avoid memory leaks
    }
  };
};
