import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { lowerCase,capitalCase,numberCase, emailRegex, specialChar } from '../../utilities/src/RegExRequired'
import {GenericApiCall} from '../../utilities/src/GenericApiCall'
import { getStorageData, setStorageData } from "framework/src/Utilities";


type responseData = {data: {id: string, type: string}, meta: {token: string}, errors: {[key: string]: string}[]}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  fullName: string;
  email:string;
  password:string;
  showPassword:boolean;
  terms_and_conditions:boolean;
  passwordErrors:any;
  showPasswordValidation: boolean;
  isEmailValid:boolean;
  isFormSubmit:boolean;
  errorMsg:string;
  terms_and_conditions_errMsg:string,
  isPasswordValid: boolean;
  isLoading: boolean;
  isChecked:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SignuploginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  signUpApiCallId :string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      fullName:'',
      email:'',
      password:'',
      showPassword:true,
      terms_and_conditions:false,
      isChecked:false,
      passwordErrors:[
        { error: 'capital', msg: 'At least one capital letter'},
        { error: 'lower', msg: 'At least one lowercase letter'},
        { error: 'number', msg: 'At least one number'},
        { error: 'length', msg: 'Minimum character length is 8 characters'}
      ],
      showPasswordValidation: false,
      isEmailValid: true,
      isFormSubmit:false,
      errorMsg:'',
      terms_and_conditions_errMsg:'',
      isPasswordValid:true,
      isLoading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log(errorReponse);
      
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      

      if (apiRequestCallId != null) {
        if(apiRequestCallId == this.signUpApiCallId){
          this.setState({ isLoading: false })
          this.handleErrorResponse(responseJson)
        } else {
          this.setState({ isLoading: false })
        } 
      } else {
        this.setState({ isLoading: false })
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  passwordInputError = () => (this.state.isFormSubmit && !this.state.password ) || this.state.password?.length > 20;
  
  passwordMaxLengthError = () => this.state.password?.length > 20;
  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };
  setFullName = async (full_name: string) => {
    this.setState({ fullName: full_name })
  }
  setEmailAddress = async (email_address: string) => {
    this.setState({ email: email_address })
  }
  setPassword = async (password: string) => {
    this.setState({ isPasswordValid: this.checkTypeOfErrors('default', password) })
    this.setState({ password: password })
    this.state.passwordErrors.map((error: any) => {
      error.isValid = this.checkTypeOfErrors(error.error, password);
    })
  }
  checkTypeOfErrors=(typeOfError:string,password:string)=>{
    switch (typeOfError) {
      case 'length':
          return password.length>7
      case 'lower':

        return lowerCase.test(password)
      case 'capital':
          
          return capitalCase.test(password)
      case 'number':
          return numberCase.test(password)
        
      default:
        if(numberCase.test(password) && lowerCase.test(password) && capitalCase.test(password) && password.length>7 && password.length<21){
          return true
        }
        else{
        return false
        }
    }
  }
  setTermsAndCondition=(value:boolean)=>{
    this.setState({terms_and_conditions:value,terms_and_conditions_errMsg:''})
    this.setState({
      isChecked:value
    })
      
  }
  setShowPassword=()=>{
    this.setState({showPassword:!this.state.showPassword})
  }
  validatePassword=(action:string)=>{
    setTimeout(()=>{
      if (action == 'focus') {
      this.setState({ showPasswordValidation: true })
    } else if (action == 'blur' && this.state.password.length == 0) {
      this.setState({ showPasswordValidation: false })
    } else {
      this.setState({ showPasswordValidation: true })
    }
  },200)
  }

  // Customizable Area Start
  validateEmail = ()=>{
    setTimeout(()=>{
    if(!emailRegex.test(this.state.email) && this.state.email){
      this.setState({isEmailValid:false})
    }else{
      this.setState({isEmailValid:true})
    }
  },200)
  }

  handleErrorResponse = (responseJson: responseData) => {
    if(responseJson && responseJson.errors){
      let ErrorMessage = "";
      if(responseJson.errors[0]['full_name']?.length) ErrorMessage = `Full name ${responseJson.errors[0]['full_name']}`
      else ErrorMessage = responseJson.errors[0]['message'];
      this.setState({errorMsg: ErrorMessage})
    }
    else if(responseJson){
      this.checkForScreenParams(responseJson);
    }
    
  }
  submitForm=(event:any)=>{
    event.preventDefault()
    this.setState({ isFormSubmit: true, errorMsg:'' })
    console.log('special char check--->',specialChar.test(this.state.password))
    if(!this.state.email || !this.state.password || !this.state.fullName || this.state.fullName.length < 2 || this.state.fullName.length > 50 || !this.state.isEmailValid){
      return false
    }
    else if(!this.state.terms_and_conditions){
      this.setState({terms_and_conditions_errMsg:configJSON.termsError})
      return false
    }
    else if(this.state.isPasswordValid && !specialChar.test(this.state.password)){
      this.setState({errorMsg:`Following chars not alloewd: ~+-=<>,?{}[]:; “ ”,' '`})
      return false
    }
    else if(this.state.isPasswordValid){
    let httpBody = {
      "data": {
        "type": "email_account",
        "attributes": {
          "full_name": this.state.fullName,
          "email": this.state.email,
          "password": this.state.password,
          "terms_of_service": this.state.terms_and_conditions
        }
      }
    }
      this.setState({ isLoading: true })
    let requestMesg = GenericApiCall(configJSON.exampleApiContentType, configJSON.exampleAPiMethod, configJSON.signUpApiEndPoint, '', JSON.stringify(httpBody))
    this.signUpApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
    }
  }
  async componentDidMount(){
    const getFullName = await getStorageData("fullName")
      this.setState({
        fullName: getFullName
      })
    const getEmail = await getStorageData("email")
      this.setState({
        email: getEmail
      })
    const getNumSecurity = await getStorageData("numSecurity")
    const prefix = "xyz";
    const suffix = "123";
    const originalPassword = JSON.parse(getNumSecurity)?.slice(prefix.length, -suffix.length); 
      this.setState({
        password: originalPassword
      })
    let tncs = await getStorageData('tncs')
    let pnps = await getStorageData('pnps')
    if(tncs === 'True' && pnps === 'True'){
      this.setState({
        isChecked:true
      })
    }
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.fullName != this.state.fullName) {
      setStorageData("fullName", this.state.fullName);
    }
    if (prevState.email != this.state.email) {
      setStorageData("email", this.state.email);
    }
    if (prevState.password != this.state.password) {
      const prefix = "xyz"; 
      const suffix = "123"; 
      setStorageData("numSecurity", JSON.stringify(`${prefix}${this.state.password ? this.state.password : ''}${suffix}`));
    }
  if (this.state.isChecked && !this.state.terms_and_conditions) {
      this.setState({
        terms_and_conditions: true
      })
    }
  }
  navigateToLink=(navigationLink:string)=>{
    const linkMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    linkMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    linkMsg.addData(getName(MessageEnum.NavigationTargetMessage), navigationLink);
    this.send(linkMsg);
  }
  checkForScreenParams = (responseJson: responseData) => {
    let nextPage = "Onboardingguide";

    localStorage.setItem("token",responseJson.meta.token)
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    const navigatedFromUrlScreen = this.props.navigation.getParam('screenid');
    if (navigatedFromUrlScreen) {
      localStorage.setItem("scannedQrValue", navigatedFromUrlScreen);
      nextPage = "PaymentScreen";
    };
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), nextPage);
    this.send(msg);
  }
  // Customizable Area End
}
