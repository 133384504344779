import React from "react";

// Customizable Area Start

import { View, StyleSheet } from "react-native";

import { imgVisbility, imgVisbilityOff } from "./assets";
// Customizable Area End

import Cfonplatformscreencreation0Controller, {
  Props,
  configJSON,
} from "./Cfonplatformscreencreation0Controller";
import { Box, CircularProgress } from "@material-ui/core";
import "video.js/dist/video-js.css";

export default class Cfonplatformscreencreation0 extends Cfonplatformscreencreation0Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderImageWithQrCode = () => {
    return <div style={{
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      height: "100%"
    }}>
      <img src={require('../assets/Image_logo_white.png')} style={{ height: '40vh', marginTop: "40px" }} />
      <img data-test-id="qr-block" src={this.state.qrCode} style={{ height: '40vh', marginBottom: "40px" }} alt="ads" />
    </div>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={{ width: "100%", height: '100%', justifyContent: 'center', alignItems: 'center', background: "#000" }}>
        {!this.state.screenAssetsData.length && !this.state.loader && this.state.qrCode ?
          this.renderImageWithQrCode()
          : ""}
        {!this.state.screenAssetsData.length && (this.state.loader || this.state.videoLoader) && (
          <div style={styles.circularProgressDiv}>
            <CircularProgress style={{ color: 'grey' }} />
          </div>
        )}
        {this.state.assetType !== "" &&
          <div style={{
            display: 'block',
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            aspectRatio: '1/1',
            maxWidth: '100vw',
            height: "100%",
            overflow: 'hidden',
            background: '#000'
          }}
            data-test-id="fade-element"
          >
            {this.state.assetType.includes("image") ?
              
              <>
              {this.state.imgLoading && <div style={styles.circularProgressDiv}>
                          <CircularProgress style={{ color: 'grey',zIndex:99999 }} />
                        </div>} 
              {this.state.showQRCode ? (
                this.renderImageWithQrCode()
              ) : (
                this.state.screenAssetsData.length>0 && this.state.screenAssetsData.map((item: any, index: any) => (
                  <img
                  key={`${index}-${this.state.current}`}
                      data-test-id={`img-block-${index}`}
                      src={item}
                      id="img-element"
                      alt="ads"
                      onError={this.handleLoadError}
                      onLoad={() => {
                        if (index === this.state.current) {
                          console.log(`✅ Image ${index} fully loaded`);
                          this.handleImageLoad();
                        }
                      }}
                      style={{
                        objectFit: "contain",
                        display:'block',
                        opacity:
                        index === this.state.current
                          ? this.state.imgLoading
                            ? 0  
                            : 1 
                          : index === this.state.prev
                          ? 0
                          : 0, 
                        visibility: index === this.state.current || index === this.state.prev ? 'visible' : 'hidden',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: index === this.state.current ? 10 : 0, 
                        transition: "opacity 1.5s ease-in-out",
                      }}
                    />
                ))
              )}

              </>
              : ""
            }

            {this.state.assetType.includes("video") && this.state.screenAssetsData.length > 0 &&
              <div style={{ position: 'relative' }}>
                {this.state.videoLoader && (
                  <div
                    ref={this.loaderRef}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 10, 
                    }}
                  >
                    <div style={styles.circularProgressDiv}>
                      <CircularProgress style={{ color: 'grey' }} />
                    </div>
                  </div>
                )}
                {this.state.showQRCode ? (
  this.renderImageWithQrCode()
) : (
                <video
                  data-test-id="video-block"
                  id="myVideo"
                  ref={this.videoRef}
                  src={this.state.screenAssetsData[this.state.current]}
                  autoPlay
                  muted
                  playsInline
                  preload="none"
                  onLoadStart={this.handleLoadStart}
                  onLoadedMetadata={this.handleCanPlayThrough}
                  onEnded={this.handleVideoEnd}
                  onError={this.handleLoadError}
                  style={{ width: '100%', height: '100vh', opacity: this.state.opacity, transition:'opacity 0.4s ease-in-out' }}
                  crossOrigin="anonymous"
                />)}
                {this.state.showButton && !this.state.videoLoader && !this.state.showQRCode && !this.state.error && (
                <button data-test-id="unmute-btn" style={styles.unmuteBtn} onClick={this.handleUnmute}>
                  Unmute
                </button>
              )}
              </div>
            }
          </div>
        }
        {this.state.error && <Box position={"fixed"} style={styles.errorWrapper}>
          <div data-test-id="error-block" style={{ ...styles.errorText, padding: "5px 15px" }}>{this.state.error}</div>
        </Box>}
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",


  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
  circularProgressDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  errorWrapper: {
    bottom: "20px",
    color: "red",
    left: "0",
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  errorText: {
    backgroundColor: "rgb(254, 226, 226)",
    borderRadius: 5,
  },
  unmuteBtn: {
    position: 'absolute',
    bottom: 20,
    right: 25,
    padding: 6,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: 6,
    borderColor: '#fff',
    borderStyle: 'solid',
    borderWidth: 1,
  }
});


// Customizable Area End
