Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfonplatformscreencreation0";
exports.labelBodyText = "cfonplatformscreencreation0 Body";

exports.btnExampleTitle = "CLICK ME";
exports.screenAssetsAPiEndPoint = "account_block/accounts/current_screen_assets?uniq_code=";
exports.screenAssetsApiContentType = "application/json";
exports.screenAssetsApiMethodType = "GET";
exports.cloudinaryCloud = 'dyeehjkd7'
// Customizable Area End