import React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Dialog,
  IconButton,
} from "@material-ui/core";
import { CloudUpload, AddCircleOutline } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close'; 


export const FileRejectionScreen = (props: any) => {
    const {webStyle, navigation} = props;
    const handleNavigation = () => {
        localStorage.setItem("from", "rejectedScreen");
        navigation.navigate('PaymentScreen');
    }
    return (
      <>
        {/* <Typography style={webStyle.typoDescription} variant="subtitle2">
          Rejected files will be displayed here for a period of 90 days from the time of rejection.
        </Typography> */}
        {/* <Typography style={webStyle.typoGuide} variant="body1">
          <span style={webStyle.link}>
            Guides and Information
          </span>
        </Typography> */}
        <Box style={webStyle.root}>
          <Box style={webStyle.uploadBox}>
            <CloudUpload style={webStyle.uploadIcon} />
            <Typography style={webStyle.uploadText} variant="h6">Upload from system</Typography>
            <Typography style={webStyle.uploadDesc} variant="body2" onClick={props.handleDescModalOpen}>Description of supported files.</Typography>
            <Button
              data-test-id="upload_button"
              variant="text"
              startIcon={<AddCircleOutline />}
              style={webStyle.button}
              onClick={handleNavigation}
            >
              Upload media
            </Button>
          </Box>
          <Dialog
            fullScreen
            open={props.state.openDescModal}
            keepMounted
            hideBackdrop 
            disableEnforceFocus
            disableBackdropClick
            aria-labelledby="description-dialog"
            aria-describedby="description-dialog"
            BackdropProps={{
              invisible: true,
            }}
            style={{
              position: "fixed",
              top: "0px",
            }}
            PaperProps={{
              style: {
                maxHeight: '80%',
                height: '80%',
                borderTopRightRadius: "20px",
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                background: '#000'
              },
            }}
          >
            <Box>
              <Box 
                style={{
                  padding: "22px 24px 10px 24px",
                  borderBottom: "1px solid #E2E8F0",
                  position: "sticky",
                  top: "0",
                  zIndex: 9999,
                  background: "#000",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography 
                  style={{
                    fontSize: "20px",
                    color: "#F8FAFC",
                    fontWeight: 700,
                    lineHeight: "28px",
                  }}
                  data-test-id='technical-requirement-title'
                >
                  Technical Requirements
                </Typography>
                <IconButton aria-label="close" data-test-id='close-desc-Button' onClick={props.handleDescModalClose} style={{
                  padding: '0px'
                }}>
                  <CloseIcon style={{
                    color: "#fff"
                  }} />
                </IconButton>
              </Box>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                padding: "32px 24px",
              }}>
                <Box style={{
                  color: "#F1F5F9",
                  fontSize: "14px",
                  lineHeight: "18px",
                  fontWeight: 400
                }}>
                  <ul style={{
                    margin: "0px",
                    paddingLeft: "16px" 
                  }}> 
                    <li style={{
                      marginBottom: "10px",
                    }}>
                      <span style={{
                        fontSize: "16px",
                        fontWeight: 700,
                      }}>
                        Image
                      </span>
                      <ul style={{
                        margin: "0px",
                        paddingLeft: "16px" 
                      }}>
                        <li style={{
                          marginBottom: "10px",
                        }}>
                          <strong>Formats Supported:</strong> All formats
                        </li>
                        <li style={{
                          marginBottom: "10px",
                        }}>
                          <strong>Image Size:</strong> Up to 12 MB
                        </li>
                        <li style={{
                          marginBottom: "10px",
                        }}>
                          <strong>Image Dimensions:</strong> Minimum 50x50 pixels
                        </li>
                        <li>
                          <strong>Recommendations:</strong>
                          <ul style={{
                            paddingLeft: "15px" ,
                            margin: "0px",
                          }}>
                            <li style={{
                              marginBottom: "10px",
                            }}>
                              Don't downscale images to less than 300x300 pixels as it might reduce accuracy.
                            </li>
                            <li style={{
                              marginBottom: "10px",
                            }}>
                              Preferred formats for faster processing: WEBP, JPEG, JPEG 2000.
                            </li>
                            <li>
                              For optimized usage, keep images typically less than 1 MB.
                            </li>
                          </ul>
                        </li>
                      </ul> 
                    </li>
                    <li style={{
                      marginBottom: "10px",
                    }}>
                      <span style={{
                        fontSize: "16px",
                        fontWeight: 700,
                      }}>
                        Video
                      </span>
                      <ul style={{
                        paddingLeft: "16px", 
                        margin: "0px",
                      }}>
                        <li style={{
                          marginBottom: "10px",
                        }}>
                          <strong>Formats Supported:</strong> All common video formats like MP4, AVI, MOV, WMV, FLV, WEBM, and MKV (excluding advanced MKV
                            variants).
                        </li>
                        <li style={{
                          marginBottom: "10px",
                        }}>
                          <strong>Video Size:</strong> For larger videos, files up to a 100MB limit are supported.
                        </li>
                        <li style={{
                          marginBottom: "10px",
                        }}>

                          <strong>Video Dimensions and Resolution:</strong>
                          <ul style={{
                            margin: '0',
                            paddingLeft: '15px'
                          }}>
                            <li style={{
                              marginBottom: "10px",
                            }}>
                              Minimum dimensions: 50 pixels in width or height.
                            </li>
                            <li>Recommended resolution: 480p quality is usually enough.</li>
                          </ul>
                        </li>
                        <li><strong>Video Length:</strong> Videos should be less than 60 seconds long.</li>
                      </ul>
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Dialog>
        </Box>
      </>
    )
  }