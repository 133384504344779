import React, { ChangeEvent, createRef } from "react";

import {
  Container,
  Button,
  Grid,
  IconButton,
  Typography,
  InputLabel,
  OutlinedInput,
  Box,
  Divider,
	TextField,
  Drawer,
  Collapse,
  CircularProgress
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { ArrowBack, CheckCircle, Done, Error, PhotoCameraOutlined, ExpandMore } from "@material-ui/icons";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { GenericApiCall, checkTokenError } from "../../../blocks/utilities/src/GenericApiCall";
import { getToken } from "../../../blocks/utilities/src/RegExRequired";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";

import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("../config");
const defaultAvatar = require("../../assets/avatar.png")

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiCollapse: {
      root: {
        '&.alertCollapse': {
          position: "fixed",
          bottom: '8vh',
          width: '85%',
          display: 'flex',
          justifyContent: 'center'
        },
        '&.alertCollapse > .MuiCollapse-wrapper-116': {
          width: '80% !important'
        },
        '&.alertCollapse > .MuiCollapse-wrapper-116 > .MuiCollapse-wrapperInner-117': {
          width: '84%'
        },
        '&.alertCollapse > .MuiCollapse-wrapper-116 > .MuiCollapse-wrapperInner-117 > div': {
          backgroundColor: 'white',
          padding: '8px 16px',

          borderRadius: '8px',
          color: '#0F172A',
          fontSize: '1em',
          boxShadow: '0px 4px 7px grey',

        }
      }
    },
    MuiDrawer: {
      root: {
        '&.upload-picture-Alert1 > .MuiPaper-root': {
          borderRadius: '16px !important',
          margin: '8px',
          marginBottom: '72px',
          backgroundColor: '#E1E1E2'
        },

      }
    },
    MuiButton: {
      root: {
        fontSize: '1em',
        textTransform: 'none',
        borderRadius: '8px',
        margin: '9px 0 9px',
        fontWeight: 700,
        '&.conatinedBtn': {
          color: 'white',
          background: 'linear-gradient(135deg, rgba(140,139,139,1) 7%, rgba(24,23,25,1) 100%);',
          padding: '12',
          marginTop: '12px',
          marginBottom: '16px'
        },
        '&.textBtn': {
          marginTop: '24px'
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: 700,
        color: "#F1F5F9",
        marginBottom: "4px"
      }
    },
    MuiOutlinedInput: {
      root: {
        border: "1px solid #64748B",
        borderRadius: "8px",
        "& $notchedOutline": {
          background: "transparent",
        },
        "&:focus": {
          border: "1px solid #64748B",
        },
        "&:hover $notchedOutline": {
          border: "none",
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          }
        }
      },
      input: {
        background: "transparent",
        fontSize: "16px",
        color: "#FFF",
        fontWeight: 400,
        lineHeight: '24px',
        fontFamily: "Inter",
				'&::placeholder': {
					color: '#94A3B8',
					opacity: 1,
				}
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: 700,
        color: "#F8FAFC",
        marginBottom: "4px"
      }
    },
    MuiInputBase: {

      input: {
        border: "none",
        background: "transparent",
        borderRadius: "8px",
        fontSize: "14px",
        color: "#94A3B8",
        fontWeight: 400,
        fontFamily: "Inter",

      },
    },
  }
});

import { Alert } from "@material-ui/lab";

const warningImage = require("../../assets/delete-icon.png");
const headerImage = require("../../assets/group_header.png");

export interface Props {
  navigation: any;
  id: string;
	handlePageState?: (data: string) => void;
  handleMessage?: (message: string) => void;
}

interface locationType {
  name: string;
  abbreviation: string;
}

interface S {
  loading: boolean;
	fullName: string;
	language: string;
	location: string;
	profileImageUrl: '';
  saveEnabled?: boolean;
	profilePicture: string | ArrayBuffer | null | undefined;
	saveButtonDisable: boolean;
  enableField: boolean;
  showChangeProfilePopUp: boolean;
	showErrMsg: boolean;
	errorMsg: {
    type: "error" | "warning" | "info" | "success" | "",
    msg: string
  },
  profileImageRemoved: boolean;
  imageSrc: string | ArrayBuffer | null | undefined | any,
  closeUploadBtn: boolean;
  imgValidation: any;
  locations: string[]
}

interface SS {
  id: string;

}

export default class CustomisableUserProfiles extends BlockComponent<Props, S, SS> {

	uploadProfileRef: any;
  takePhotoRef: any;
  errorMsgInterval: any;
  handleFileInput: any;
  canvasRef: any;
	updateUserProfileApiCallId: string = '';
	getUserProfileApiCallId: string = '';
	getApiCallId: string = '';
	getLocationsApiCallId: string = '';

  constructor(props: Props) {

		super(props);

    this.uploadProfileRef = createRef();
    this.handleFileInput = createRef();
		
    this.receive = this.receive.bind(this);
		this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

		this.state = {
			loading: false,
			fullName: '',
			language: '',
			location: '',
			saveEnabled: false,
			profilePicture: '',
			profileImageUrl: '',
			saveButtonDisable: false,
			enableField: false,
			showChangeProfilePopUp: false,
			showErrMsg: false,
			errorMsg: {
				type: '',
        msg: ''
			},
      locations: [],
			profileImageRemoved: false,
			imageSrc: '',
			closeUploadBtn: false,
			imgValidation: '',
		}

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

	async receive(from: string, message: Message) {
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
			const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      if (responseJson) {
        if (responseJson.errors) {
          this.handleErrorResponse(responseJson)
        }
        else {
          if (apiRequestCallId === this.updateUserProfileApiCallId) {
						localStorage.setItem('showProfileUpdateSuccess', JSON.stringify(true));
						
						this.props.handleMessage && this.props.handleMessage('Profile successfully updated');
						this.props.handlePageState && this.props.handlePageState('');
					} else if(apiRequestCallId === this.getUserProfileApiCallId) {
						this.setState({
							fullName: responseJson.data.attributes.full_name,
							location: responseJson.data.attributes.location_name ? responseJson.data.attributes.location_name : '',
							language: responseJson.data.attributes.language ? responseJson.data.attributes.language : '',
							profilePicture: responseJson.data.attributes.avatar	 ? responseJson.data.attributes.avatar : '',
			
						})
					} else if(apiRequestCallId === this.getLocationsApiCallId) {
            const formattedStates = responseJson.states.map((data: locationType) => data.name);
						this.setState({
              locations: formattedStates ?? []
						})
					}
        }
      }
    }
  }

	async componentDidMount() {
		let token = getToken();
		if (!token) {
      this.props.navigation.navigate('EmailAccountLoginBlock')
    } else {
			this.fetchUserProfile(token);
      this.fetchLocationListAPI(token);
		}
	}

	handleBack = () => {
		this.props.handlePageState && this.props.handlePageState('');
	}

	fetchUserProfile = (token: string) => {
    let requestMesg = GenericApiCall(configJSON.validationApiContentType, configJSON.httpGetMethod, configJSON.getUserProfileApiEndPoint, token)
    this.getUserProfileApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }

  fetchLocationListAPI = (token: string) => {
    let requestMessage = GenericApiCall(configJSON.validationApiContentType, configJSON.httpGetMethod, configJSON.getLocationListApiEndPoint, token)
    this.getLocationsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

	handleErrorResponse = (responseJson: any) => {
    let errorMsg = checkTokenError(responseJson)
    if (errorMsg.type == 'token') {
      this.setState({
        errorMsg: { type: 'error', msg: errorMsg.msg },
        loading: false,
        showErrMsg: true
      })
    }
    else {
      let msg: string[] = responseJson.errors;
      this.setState({
        errorMsg: { type: 'error', msg: msg[0] },
        showErrMsg: true,
        loading: false
      });
    }

    if (this.errorMsgInterval) {
      clearInterval(this.errorMsgInterval);
    }
    this.errorMsgInterval = setTimeout(() => {
      this.setState({ showErrMsg: false });
    }, 5000);
  }

	editUserProfile = (field: string, value: string) => {
    switch (field) {
      case 'fullname':
        this.setState({ fullName: value })
        break;
      case 'location':
        this.setState({ location: value })
        break;
      case 'language':
        this.setState({ language: value })
        break;
      default:
        break;
    }
  }

	handleClick = () => {
		if (this.handleFileInput.current) {
      this.handleFileInput.current.click();
    }
	};
	clearFileInput = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.value = "";
    }
  };
	clickImage = (target:any, reference: React.RefObject<HTMLInputElement>) => {
		if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);
        this.setState({ profilePicture: newUrl, showChangeProfilePopUp: false, profileImageRemoved: false, profileImageUrl: file })
      }
    }
    this.clearFileInput(reference)
	};
	handleImageChange = (event:any) => {
		if (event.target.files) {
      if (event.target.files.length !== 0) {
        const file = event.target.files[0];
        const newUrl = URL.createObjectURL(file);
        this.setState({ profilePicture: newUrl, showChangeProfilePopUp: false, profileImageRemoved: false, profileImageUrl: file })
      }
    }
	};
	getImage = (event: ChangeEvent<HTMLInputElement>, reference: React.RefObject<HTMLInputElement>) => {
		let fileName: any = event.target.files && event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png"];
    const fileType = fileName.type;

    if (!allowedTypes.includes(fileType)) {
      this.setState({ imgValidation: { show: true, type: "Invalid format!" }, showChangeProfilePopUp: false });
      setTimeout(() => {
        this.setState({ imgValidation: { show: false, type: "" } });
      }, 3000);
      return;
    }
    this.setState({ profileImageUrl: fileName })
    const reader = new FileReader();
    const blob = new Blob([fileName], { type: 'text/plain' });
    reader.readAsDataURL(blob)
    reader.onload = (event) => {
      this.setState({ profilePicture: event.target?.result, showChangeProfilePopUp: false, profileImageRemoved: false })
    };
    this.clearFileInput(reference)
	};
	updateUploadProfile = (reference: React.RefObject<HTMLInputElement>) => {
    reference.current && reference.current.click();

	};
	removeImage = () => {
    this.setState({ profileImageRemoved: true, profilePicture: '', profileImageUrl: '', showChangeProfilePopUp: false })
	};
	openPopUp = () => {
    this.setState({ showChangeProfilePopUp: true })
	};
	closePopUp = () => {
    this.setState({ showChangeProfilePopUp: false })
	};

	updateProfile = () => {
		let token = getToken();
    token = token ? token : ''
    this.setState({ saveEnabled: true})
    if (!this.state.fullName || this.state.fullName.length > 50 || !/^[a-zA-Z\s]*$/.test(this.state.fullName) || this.state.location == "") {
      return false
    }

      let bodyData = new FormData();
      bodyData.append("full_name", this.state.fullName)
      bodyData.append("location", this.state.location || '')
      bodyData.append("language", this.state.language)
      if (this.state.profileImageUrl) {
        bodyData.append("avatar", this.state.profileImageUrl)
      }
      if (this.state.profileImageRemoved) {
        bodyData.append("remove_photo", JSON.stringify(true))
      }
      this.setState({ loading: true })
      let requestMessage = GenericApiCall('', configJSON.httpPutMethod, configJSON.updateUserProfileApiEndPoint, token, bodyData)
      this.updateUserProfileApiCallId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage)
	}

  render() {
    const fullNameRegex = /^[a-zA-Z\s]*$/;
    const showInvalidCharacters = !fullNameRegex.test(this.state.fullName);
    return (
      <ThemeProvider theme={theme}>
        <Box style={{ backgroundColor: '#212121', height: '100svh'}}>

          <Container style={{ backgroundImage: `url(${headerImage})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', padding: 0, paddingBottom: '85px', position: 'relative', width: "100%" }}>

            <Box display={'flex'}>
              <Grid item xs>
                <IconButton data-test-id="backArrowBtn" onClick={() => this.handleBack()}>
                  <ArrowBack style={webStyle.backBtn} />
                </IconButton>
              </Grid>
              <Grid item sm xs={8} style={webStyle.headerWrapper}>
                <Typography style={{ color: 'white', textTransform: 'uppercase', fontSize: '14px', fontWeight: 700, textAlign: 'center', lineHeight: '22px' }}>{'EDIT PROFILE'}</Typography>
              </Grid>
              <Grid item xs container style={webStyle.doneWrapper}>
                <IconButton data-test-id="updateProfileBtn" style={{ padding: '8px 8px 8px 8px' }} onClick={() => this.updateProfile()}>
                  <Done style={webStyle.doneIcon} />
                </IconButton>
              </Grid>
            </Box>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', position: 'absolute', top: '65px' }}>
              <IconButton data-test-id="selectImageBtn" onClick={() => this.openPopUp()} style={{ padding: '0px', backgroundColor: 'black', borderRadius: '50%', border: '4px solid', borderColor: `${this.state.showChangeProfilePopUp ? "#F0E5FF" : "#FFF"}`}}>
                <div style={{ width: '130px', height: '130px', backgroundColor: '#F1F5F9', backgroundImage: this.state.profilePicture ? `url(${this.state.profilePicture})` : `url(${defaultAvatar})`, backgroundSize: 'cover', borderRadius: '50%',  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: "relative", opacity: 0.25 }}>
                </div>
              </IconButton>
              <div style={{position: "absolute", display: 'flex', flexDirection: 'column', top: '47px', justifyContent: 'center', alignItems: 'center'}} onClick={() => this.openPopUp()} >
                <PhotoCameraOutlined style={{ fontSize: '24px', color: 'white', marginBottom: '5px' }} />
                <Typography style={{ fontSize: '14px', fontWeight: 700, color: 'white' }}>{this.state.profilePicture ? 'Edit picture' : 'Add picture'}</Typography>
              </div>
            </div>
          </Container>
          {this.state.loading && (
            <Box sx={webStyle.loaderWrapper} position={"fixed"}>
              <CircularProgress />
            </Box>
          )}
          <Container maxWidth='md' >
            <form style={webStyle.form}>
              <InputLabel style={webStyle.inputFielTitle}>
                Full name
              </InputLabel>
              <OutlinedInput
                fullWidth
                name="fullName"
                value={this.state.fullName}
                type={'text'}
                id="fullName"
                style={webStyle.inputFieldValue}
                data-test-id='fullNameTestId'
								placeholder="Enter your name"
                autoComplete="off"
                onChange={(event) => this.editUserProfile('fullname', event.target.value)}
              />

              {(this.state.saveEnabled && !this.state.fullName) && <span style={{ fontSize: '0.75em', color: '#DC2626', marginBottom: '24px' }}>Full Name is required</span>}
              {(this.state.saveEnabled && this.state.fullName.length > 50) && <span style={{ fontSize: '0.75em', color: '#DC2626', marginBottom: '24px' }}>Name cannot exceed 50 characters.</span>}
              {showInvalidCharacters && (
                <div style={{ fontSize: '0.75em', color: '#DC2626', marginBottom: '6px' }}>
                  Only alphabetic characters are allowed
                </div>
              )}
              {/* <InputLabel style={webStyle.inputFielTitle}>
                Language
              </InputLabel>
							<Autocomplete
								id="combo-box-demo"
								fullWidth
								style={webStyle.autoCompleteStyle}
								options={['English', 'Arabic']}
                PaperComponent={(props)=> <CustomPaper {...props} / >}
								getOptionLabel={(option) => option}
								renderInput={(params) => <MenuIcon><TextField {...params} placeholder="Select option" variant="outlined" /></MenuIcon>}
								popupIcon={<ExpandMore style={{color: "white"}} />}
								value={this.state.language}
								onChange={(event, value) => {
                  this.editUserProfile('language', value || '')
                }}
							/>
              {(this.state.saveEnabled && !this.state.language) && <span style={{ fontSize: '0.75em', color: '#DC2626', marginBottom: '24px' }}>Language is required</span>} */}

              <InputLabel style={webStyle.inputFielTitle}>
                Location
              </InputLabel>
							<Autocomplete
								id="combo-box-demo"
                data-test-id="location-selection"
								fullWidth
								style={webStyle.autoCompleteStyle}
								options={this.state.locations}
                ListboxProps={{
                  style: {
                    maxHeight: 'calc(100vh - 560px)',
                  },
                }}
                PaperComponent={(props)=> <CustomPaper {...props} / >}
								getOptionLabel={(option) => option}
								renderInput={(params) => <MenuIcon><TextField {...params} placeholder="Select option" variant="outlined" /></MenuIcon>}
								popupIcon={<ExpandMore style={{color: "#94A3B8"}} />}
								value={this.state.location}
                noOptionsText="No country found"
								onChange={(event, value) => {
                  this.editUserProfile("location", value === "No country found" ? "" : value || "");
                }}
                filterOptions={(options, state) => {
                  const filtered = options.filter((option) =>
                    option.toLowerCase().includes(state.inputValue.toLowerCase())
                  );
                  return filtered.length > 0 ? filtered : ["No country found"];
                }}
							/>

              
              <Collapse className="alertCollapse" in={this.state.showErrMsg} >
                <Alert icon={this.state.errorMsg.type == 'error' ? <Error style={{ color: 'red' }} fontSize="inherit" /> : <CheckCircle style={{ color: '#34D399' }} fontSize="inherit" />} severity={this.state.errorMsg.type == 'error' ? "error" : 'success'} style={{ width: '100%' }}>
                  {this.state.errorMsg.msg}
                </Alert>
              </Collapse>
            </form>
            <Drawer data-test-id="upload-picture-alert1" className="upload-picture-Alert1" anchor={'bottom'} open={this.state.showChangeProfilePopUp}  
            ModalProps={{
              BackdropProps: {
                onClick: this.closePopUp, 
              },
            }}
            >
              <div>
                <div style={{ display: 'flex', flexDirection: 'column', padding: '8px 24px 0px' }}>

                  <Typography
                    style={{ fontSize: '0.84em', fontWeight: 400, color: '#3C3C43', textAlign: 'center', padding: '8px' }}
                  >
                    Edit profile picture
                  </Typography>
                  <Divider />
                  <input
                    data-test-id="click-photo"
                    ref={this.takePhotoRef}
                    name="takePhoto"
                    style={{ visibility: 'collapse', height: '2px' }}
                    type="file"
                    accept="image/*"
                    capture='environment'
                    onChange={(event) => this.clickImage(event.target, this.takePhotoRef)}
                  />

                  <label>
                    <input
                      data-test-id="take-photo"
                      style={{ visibility: 'collapse', height: '2px' }}
                      type="file"
                      accept="image/*"
                      capture="environment"
                      ref={this.handleFileInput}
                      onChange={(event) => this.handleImageChange(event)}
                    />
                  </label>

                  <Button
                    data-test-id={`clickBtn`}
                    variant="text"
                    onClick={() => this.handleClick()}
                  >
                    {'Take photo'}
                  </Button>

                  <Divider />
                  <input
                    data-test-id="image-upload"
                    ref={this.uploadProfileRef}
                    name="uploadProfile"
                    style={{ visibility: 'collapse', height: '2px' }}
                    type="file"
                    accept="image/*"
                    onChange={(event) => this.getImage(event, this.uploadProfileRef)}
                  />
                  <Button
                    data-test-id={`uploadBtn`}
                    variant="text"
                    onClick={() => this.updateUploadProfile(this.uploadProfileRef)}
                  >
                    {'Upload photo'}
                  </Button>
                  <Divider />
                  <Button
                    data-test-id={`removeBtn`}
                    variant="text"
                    onClick={() => this.removeImage()}
                  >
                    Remove photo
                  </Button>
                  <Divider />

                </div>
                <div className="MuiPaper-root MuiDrawer-paper MuiDrawer-paperAnchorBottom MuiPaper-elevation16" style={{ margin: '8px', borderRadius: '16px' }}>
                  <Button
                    variant="text"
                    onClick={() => this.closePopUp()}
                    data-test-id='cancelBtn'
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Drawer>
            {this.state.imgValidation.show &&
              <Box sx={webStyle.successStatusWrapper} data-test-id="image-type-validation">
                <Box sx={webStyle.successStatus}>
                <img src={warningImage} style={webStyle.imgStyle}/> {this.state.imgValidation.type} Upload JPEG or PNG only. 
                </Box>
              </Box>
            }
          </Container>
        </Box>

      </ThemeProvider>
    );
  }
}

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Inter",
    flexDirection: "column",
    paddingBottom: "30px",
    marginTop: '20%',
    height: '80%',
    alignItems: 'center'
  },
  backBtn: { 
    height: '24px', 
    width: '24px', 
    color: 'white' 
  },
  headerWrapper: { 
    display: 'grid', 
    alignItems: 'center' 
  },
  doneWrapper: { 
    justifyItems: 'flex-end', 
    display: 'grid' 
  },
  doneIcon: { 
    height: '24px', 
    width: '24px', 
    color: 'white' 
  },
  errorText: { 
    fontSize: '0.75em', 
    color: '#DC2626', 
    marginBottom: '24px' 
  },

  form: {
    marginTop: '120px',
    marginBottom: 0,
    width: '100%',
		'& .MuiOutlinedInput-input': {
			color: '#FFF',
		},
  },
  inputFielTitle: {
    fontSize: '14px',
    fontWeight: '700',
    margin: '0 0 4px',
    color: '#F1F5F9',
    lineHeight: '22px',
    marginTop: '10px'
  },
  inputFieldValue: {
    margin: '0px 0px 5px',
    color: '#FFF',
    backgroundColor: 'transparent',
    borderRadius: '8px',
    fontFamily: 'Inter',
    border: '1px solid #64748B',
    '&.MuiInputBase-root':{
      margin: 0
    }
  },
  uploadBtn: {
    border: '1px solid #000',
    padding: '10px 20px',
    fontWeight: 500,
    fontSize: '14px',
    background: '#d5d5d5',
    borderRadius: '5px'
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    top: 0,
    zIndex: 9999,
    width: '100%',
  },
  successStatus: {
    maxWidth: "300px",
    padding: "10px",
    display: "flex",
    margin: "0 auto",
    color: "#0F172A",
    fontSize: "16px",
    lineHeight: "24px",
    position: 'fixed',
    width: 'calc(100vw - 80px)',
    borderRadius: "8px",
    backgroundColor: "#FEE2E2",
    '& img': {
      marginRight: "8px",
      width: "24px"
    },
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'center',
    bottom: 40
  },
  successStatusWrapper: {
    bottom: "50px",
    width: "100%",
    display: "flex",
    justifyContent: 'center',
  },
  imgStyle: {
    margin: "10px 8px 10px 0"
  },
	autoCompleteStyle: {
		margin: '0px 0px',
	},
};


const CustomPaper = styled('ul')(({theme})=>({
  backgroundColor:"black",
  color:"white",
  paddingLeft: '0px',
  marginTop: 0,
  maxHeight: 'calc(100vh - 550px)'
}))


const MenuIcon = styled(Box)({
  '& .MuiButtonBase-root': {
    color: '#FFF'
  },
});