import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  CardActions,
  CircularProgress,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Grid,
  Collapse,
  Dialog,
  // Customizable Area Start
  Divider,
  Drawer,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { tickIcon, errorIcon, mainImg } from "./assets";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import CloseIcon from '@material-ui/icons/Close'; 

const {TIFFViewer}  = require("react-tiff");

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides:{
    MuiButtonBase:{
      root:{
        '&.iconButton':{
          padding:'12 4'
        }
      }
    },
    MuiButton:{
      root:{
        textTransform:'none',
        height:'3.6em',
        fontSize: '16px',
        fontWeight: 700,
        '&.enabledButton':{
          background: 'linear-gradient(135deg, rgba(140,139,139,1) 7%, rgba(24,23,25,1) 100%);',
        },
        '&.disabledButton,&.disabledButton:hover ':{
          background: 'linear-gradient(174.53deg, rgba(255, 255, 255, 0.4) 4.52%, rgba(145, 145, 145, 0.4) 103.95%)',

        },
        '&$disabled':{
          background: 'rgba(104, 104, 104, 1) !important'

        },
        
      }
    },
    MuiContainer:{
      'root':{
        padding:'0px !important',
        background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%) !important',
        minHeight: "100vh"
      }
    },
    MuiTypography: {
      'root': {
        color: "#E2E8F0"
      },
    },
    MuiPaper:{
      'root':{
        '&.Paper':{
          display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding:'40px 12px',
    margin: 'auto',
    background: 'transparent !important',
    border: '3px dashed #ccc',
    borderRadius: ' 20px !important',
  },
  '&.Paper > .uploadingCard':{
    backgroundColor:'#000',
    marginBottom:'16px',
    borderRadius:'8px',
    boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
},
  paperElevation1: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none !important"
    },
        }
      }
    },
    MuiLinearProgress:{
      barColorPrimary:{
        backgroundColor:'green !important',
      }
    },
    MuiSvgIcon: {
      root: {
        '&.cloudUpload':{
        width: "54px",
        height: "36px",
        }
      }
    },
    MuiDrawer: {
      root: {
        '&.media-drawer > .MuiPaper-root': {
          borderRadius: '16px !important',
          margin: '8px',
          marginBottom: '72px',
          backgroundColor: '#E1E1E2'
        },

      }
    },
  }
});
// Customizable Area End

import Uploadmedia3Controller, {
  Props,
  configJSON,
} from "./Uploadmedia3Controller";
import { ArrowBack, CloudUpload, Error } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

export default class Uploadmedia3 extends Uploadmedia3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  loader() {
    return this.state.loader ? (
      <Box sx={styles.loaderWrapper} position={"fixed"}>
        <CircularProgress />
      </Box>
    ) : <></>;
  }
  circularProgressWithLabel() {
    return (
      <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',height:'50px',width:"50px"}}>
        <CircularProgress />
      </Box>
    );
  }
  
  renderUploadingCard(file: any) {
    let content;
  
    if (file.type?.startsWith("image/")) {
      if (this.isTiffFile(file.name)) {
        content = (
          <div className="upload-tiff-img">
            <TIFFViewer tiff={file.preview} />
          </div>
        );
      } else {
        content = (
          <img
            src={this.getImageUrl(file)}
            alt="upload"
            data-test-id="upload-image"
            style={{
              position: "relative",
              minWidth: "50px",
              minHeight: "50px",
              maxWidth: "50px",
              maxHeight: "50px",
              objectFit: "cover",
              overflow: "hidden",
              borderRadius: "8px",
              width: "20%",
              ...styles.paddingH,
            }}
          />
        );
      }
    } else {
      if (this.state.isLoadingThumbnail) {
        content = this.circularProgressWithLabel();
      } else {
        content = (
          <img
            src={this.state.thumbnail}
            alt="upload-video"
            data-test-id="upload-video"
            style={{
              position: "relative",
              minWidth: "50px",
              minHeight: "50px",
              maxWidth: "50px",
              maxHeight: "50px",
              objectFit: "cover",
              overflow: "hidden",
              borderRadius: "8px",
              width: "20%",
              ...styles.paddingH,
            }}
          />
        );
      }
    }
  
    return <>{content}</>;
  }
  
  // Customizable Area End

  render() {
    const { uploadedFiles, uploadingFile, anchorEl, anchorEl2 } =
      this.state;
    const { classes } :any = this.props ;

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.loader()}
        <Container style={{ background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%) !important' }} maxWidth={"sm"} >
          <Grid container spacing={1} style={{ 
            height:'48px', 
            margin: '0px -8px 8px', 
            position: 'sticky', 
            top: '0',
            zIndex: 9999
          }}>
            <Grid item xs style={{ flexGrow: 0, padding: '0px 8px', }}>
              <IconButton style={{ paddingLeft: '-1px' }} onClick={()=>this.navigateToOtherScreen("PaymentScreen")}  data-test-id="backArrowBtn" >
                <ArrowBack style={{ height: '24px', color: "#FFFFFF", width: '24px', }} />
              </IconButton>
            </Grid>
            <Grid sm xs={8} item style={{ display: 'grid', padding: '4px 8px 10px', alignItems: 'center' }}>
              <Typography style={{ fontSize: '0.8em', textTransform: 'uppercase', fontWeight: 700, textAlign: 'center' }}></Typography>
            </Grid>
            <Grid xs item container style={{ padding: '4 8 24', justifyItems: 'center', display: 'grid' }}>
            </Grid>
          </Grid>
          <Typography style={{lineHeight:'40px',fontSize:'30px', color: '#fff',fontWeight:700,paddingLeft: '5vw', borderBottom: "1px solid #64748B", paddingBottom: '14px'}}>Upload</Typography>
          <div style={styles.container} >
            <div style={styles.child} >
              <Paper className={'Paper'} data-test-id="paperTestId">
                {uploadedFiles.map((file: any, indexx: number) => {
                  return (
                    this.state.status[indexx] == 'uploading' ?
                      <div className="uploadingCard" key={indexx+file.name} style={styles.uploadingCard}>
                        <div style={{ width: '100%', padding: '0px 8px' }}>
                          <LinearProgress
                            data-test-id="linearProgress"
                            variant="determinate"
                            value={this.state.progress[indexx]}
                            style={{
                              accentColor: "green",
                              margin: "0 !important",
                              backgroundColor: "white",
                              width: '95%',
                              ...styles.customColor
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-around",
                            marginTop: "10px",
                          }}
                        >
                          <CircularProgress
                            value={this.state.progress[indexx]}
                            size={20}
                            style={{
                              color: "#059669",
                              minWidth: "32px",
                              minHeight: "32px",
                              maxWidth: "32px",
                              maxHeight: "32px",
                              objectFit: "cover",
                              margin: "0 12px",
                              ...styles.paddingH
                            }}
                            variant="determinate"
                          />
                          {file.type.startsWith("image/") && (
                            this.isTiffFile(file.name)? <div className="uploading-tiff-img"><TIFFViewer tiff={file.preview} /></div> :
                            <img

                              src={file.preview}
                              alt="upload media"
                              style={{
                                position: "relative",
                                minWidth: "50px",
                                minHeight: "50px",
                                maxWidth: "50px",
                                maxHeight: "50px",
                                objectFit: "cover",
                                overflow: "hidden",
                                borderRadius: "8px",
                                ...styles.paddingH
                              }}
                            />
                          )}
                          <div style={{ ...styles.paddingH,width:'9.7em' }}>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: "bold", padding: "7px 0 5px 12px",wordBreak:'break-all' }}
                            >{`${file.name}`}</Typography>
                            <Typography
                              style={{ color: "#ccc", padding: "0px 0 14px 12px" }}
                            >{`${(file.size / 1024).toFixed(
                              2
                            )} KB`}</Typography>
                          </div>
                          <MoreVertIcon
                            className="paddingH"
                            style={{ marginRight: "16px", color: "#64748B" }}
                          />
                        </div>
                      </div>

                      :
                      <div className="uploadingCard" key={file.name+indexx} style={{ ...styles.paddingH, ...styles.uploadingCard }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-around",
                            margin: "10px 0",
                            color: "#fff",
                          }}
                        >
                          <img
                            src={this.state.status[indexx] == 'fail' ? errorIcon : tickIcon}
                            alt="upload"
                            style={{
                              color: "#059669",
                              minWidth: "26px",
                              minHeight: "26px",
                              maxWidth: "26px",
                              maxHeight: "26px",
                              objectFit: "cover",
                              margin: "0 12px",
                              width: "20%",
                            }}
                          />
                          {this.renderUploadingCard(file)}
                          <div style={{...styles.paddingH, width: '50%'}}>
                            <Typography
                              variant="body2"
                              style={{ 
                                fontWeight: "bold", 
                                padding: "0px 0 0px 12px",
                                wordBreak:'break-all', 
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {file.name}
                            </Typography>
                            <Typography
                              style={{ color: "rgb(100, 116, 139)", padding: "4px 0 0px 12px" }}
                            >{`${(file.size / 1024).toFixed(
                              2
                            )} KB`}</Typography>
                          </div>
                          <CardActions
                            data-test-id='cardActions'
                            style={{
                              padding: '0px',
                              width: '10%'
                            }}
                          >
                            <IconButton
                              className="iconButton"
                              data-test-id="iconBtn"
                              aria-haspopup="true"
                              onClick={(e) => {
                                this.setState({
                                  anchorEl: e.currentTarget, itemToDelete: indexx
                                });
                              }}
                              style={{
                                padding: '0px',
                              }}
                            >
                              <MoreVertIcon style={{ color: "#64748B" }}/>
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              data-test-id={`${indexx}closeMenuBtn`}
                              onClose={() => this.setState({ anchorEl: null })}
                              // style={{
                              //   background: "#000"
                              // }}
                              anchorPosition={{
                                top: 225,
                                left: 120
                              }}
                              PaperProps={{
                                style: {
                                  top: 225
                                }
                              }}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              MenuListProps={{
                                style: {
                                  background: "#000"
                                }
                              }}
                            >
                              {!this.state.mediaErr && <MenuItem
                                data-test-id="menuItemBtn"
                                onClick={(e) => {
                                  this.navigateToOtherScreen('Preview',file)
                                  this.setState({ anchorEl: null });
                                }}
                                style={{
                                  paddingRight: '40px'
                                }}
                              >
                                <ListItemIcon style={{
                                  minWidth: '30px'
                                }}>
                                  <PlayCircleOutlineIcon style={{
                                    color: "#94A3B8"
                                  }} />
                                </ListItemIcon>
                                <ListItemText>Preview</ListItemText>
                              </MenuItem>}
                              <MenuItem
                                data-test-id="removeItemBtn"
                                onClick={(e) => {
                                  this.handleRemove(this.state.itemToDelete, e);
                                  this.setState({ anchorEl: null });
                                }}
                                style={{
                                  paddingRight: '40px'
                                }}
                              >
                                <ListItemIcon style={{
                                  minWidth: '30px'
                                }}>
                                  <DeleteOutlinedIcon style={{
                                    color: "#94A3B8"
                                  }} />
                                </ListItemIcon>
                                <ListItemText>Delete</ListItemText>
                              </MenuItem>
                            </Menu>
                          </CardActions>
                        </div>
                      </div>
                  )
                })}

                <canvas ref={this.canvasRef} width={100} height={50} style={{ maxHeight: '0px', visibility: 'hidden' }}></canvas>
                {!uploadingFile && uploadedFiles.length === 0 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <CloudUpload className="cloudUpload" style={{marginBottom:'16px',color:'rgba(148, 163, 184, 1)', height: "48px", width: "48px" }}/>
                    <Typography style={{ color: '#F1F5F9', fontSize: '1rem', fontWeight: 700, marginBottom: '4px' }}>
                      Upload from system
                    </Typography>
                    <Typography 
                      style={{ 
                        marginBottom: '16px', 
                        fontSize: '0.9rem', 
                        fontWeight: 400, 
                        color: '#E2E8F0', 
                        cursor: 'pointer' 
                      }}
                      data-test-id="media-description"
                      onClick={() => this.handleOpenDescModal()}
                    >
                      Description of supported files.
                    </Typography>
                  </div>
                )}

                <input
                  type="file"
                  multiple={false}
                  accept={this.acceptedInputs()}
                  onChange={(event) => this.manageFileUpload(event, uploadedFiles.length)}
                  style={{ display: "none" }}
                  data-test-id="videoFileInput"
                  id="fileInput"
                  disabled={this.uploadFileDisabled()}
                  ref={this.uploadRef}
                />
                {/* <label htmlFor="fileInput"> */}
                <Button data-test-id="upload-media" component="span" fullWidth style={{ color: '#FFFFFF', fontSize: '1rem', fontWeight: 700, height: 'auto', padding: 0 }} onClick={() => this.handleUploadMediaClick()}>
                  <AddCircleOutlineIcon style={{ marginRight: "10px", color: '#FFFFFF' }} /> Upload media
                </Button>
                {/* </label> */}
              </Paper>

              {(this.state.mediaErr) &&
                <Box sx={webStyle.alertBox} className="alert-box" data-test-id="validation-alert">
                  {this.state.mediaErr}
                </Box>
              }
              <div
                className={classes?.recent}
                style={{
                  width: "100%",
                  margin: "25px 0",
                  marginBottom: '8px',
                  borderBottom: "1px solid #64748B",
                  paddingBottom: '8px',
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#64748B",
                }}
              >
                <Typography style={{ fontWeight: "bold", fontSize: '12px', color: '#94A3B8' }}>RECENT FILES</Typography>
              </div>
              <div className="uploadingCard" style={styles.uploadingCard}>
                {this.state.recentFiles?.length ? this.state.recentFiles.map((file: any, index: any)=>{
                  const isVideo = file.attributes.media_format.toLowerCase() === "video";
                  const thumbnail = isVideo ? file.thumbnail : file.attributes.file;
                  const isTiff = this.isTiffFile(file.attributes.file_name);
                  const isLoaded = this.state.thumbnailLoadingProgress[index]
                  return (
                    <div
                      key={`${file.attributes.file_name}${index}`}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        padding: '8px',
                        background: '#000',
                        borderRadius: '8px',
                      }}
                    >
                      {isTiff ? 
                      <div className="tiff-img" style={{ position: "relative", width: "50px", height: "50px" }}>
                       { isLoaded && this.circularProgressWithLabel()}
                    <TIFFViewer
                        tiff={thumbnail}
                        style={{opacity: isLoaded ? 0 : 1,
                          transition: "opacity 0.1s ease",}}
                    /> 
                      </div>
                          : 
                          <div style={{ position: "relative", width: "50px", height: "50px" }}>
                          {isLoaded && (
                            this.circularProgressWithLabel()
                          )}
                          <img
                            src={thumbnail}
                            className="uploadMedia"
                            alt="upload media"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                              borderRadius: "8px",
                              opacity: isLoaded ? 0 : 1,
                              transition: "opacity 0.1s ease",
                            }}
                          />
                        </div>
                 }  
                    <div style={{ ...styles.paddingH, width: '75%' }}>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 400, padding: "0px 0 2px 7px", color: "#E2E8F0", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: 'nowrap' }}
                      >{file.attributes.file_name}</Typography>
                      <Typography
                        style={{ padding: "0px 0 0px 7px", color: "#64748B" }}
                      >{file.attributes.file_size}</Typography>
                    </div>
                    <CardActions
                      data-test-id='cardActions2'
                      style={{
                        padding: 0,
                      }}
                    >
                      <IconButton
                        className="iconButton2"
                        data-test-id="iconBtn2"
                        aria-haspopup="true"
                        onClick={(e) => {
                          this.setState({
                            anchorEl2: { ...this.state.anchorEl2, [file.id]: e.currentTarget }, itemToDelete: index
                          });
                        }}
                        style={{
                          padding: 0
                        }}
                      >
                        <MoreVertIcon style={{ color: "#64748B" }}/>
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl2?.[file.id]}
                        open={Boolean(anchorEl2?.[file.id])}
                        data-test-id={`${index}-closeMenuBtn2`}
                        MenuListProps={{
                          style: {
                            background: "#000"
                          }
                        }}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        onClose={() => this.setState({ anchorEl2: null })}
                      >
                        <MenuItem
                          data-test-id="menuItemBtn2"
                          onClick={(e) => {
                            this.setState({ anchorEl2: null  });
                            this.navigateToOtherScreen('Preview',{ file: file, from: 'recentFiles' })
                          }}
                        >
                          <ListItemIcon>
                            <PlayCircleOutlineIcon style={{
                              color: "#94A3B8"
                            }} />
                          </ListItemIcon>
                          <ListItemText>Preview</ListItemText>
                        </MenuItem>
                      </Menu>
                    </CardActions>
                  </div>
                  )
                }) : 
                <Box>
                  <Box sx={{
                    color: "#fff",
                    padding: "20px"
                  }}>
                    No Files found!
                  </Box>
                </Box> 
                }
              </div>
            <Collapse className="alertCollapse" data-test-id="alert-notification" in={this.state.showErrMsg} >
              <Alert 
                icon={<Error style={{ color: 'red' }} fontSize="inherit" />} 
                severity={"error"}  
              >
                {this.state.errMessage}
              </Alert>
            </Collapse>
            </div>
            <Box sx={{
              position: 'fixed',
              bottom: '24px',
              width: '100%',
              maxWidth: 'calc(100% - 32px)',
              boxSizing: 'border-box',
            }}>
              <Button
                type="submit"
                fullWidth
                data-test-id="upload-continue-btn"
                variant="contained"
                disabled={this.uploadContinueButtonDisabled()}
                onClick={()=>this.navigateToOtherScreen('PaymentPreview', uploadedFiles[0])}
                className={(this.uploadContinueButtonDisabled()) ? 'disabledButton' : 'enabledButton'}
                style={{
                  color: "black",
                  marginBottom:8,
                  background: "linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)"
                }}
              >
                Continue
              </Button>
            </Box>
          </div>
        </Container>
        <Dialog
          fullScreen
          open={this.state.openDescModal}
          keepMounted
          hideBackdrop 
          disableEnforceFocus
          disableBackdropClick
          aria-labelledby="description-dialog"
          aria-describedby="description-dialog"
          BackdropProps={{
            invisible: true,
          }}
          style={{
            position: "fixed",
            top: "200px",
          }}
          PaperProps={{
            style: {
              height: "auto",
              borderTopRightRadius: "20px",
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              background: '#000'
            },
          }}
        >
          <Box>
            <Box 
              style={{
                padding: "22px 24px 10px 24px",
                borderBottom: "1px solid #E2E8F0",
                position: "sticky",
                top: "0",
                zIndex: 9999,
                background: "#000",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography 
                style={{
                  fontSize: "20px",
                  color: "#F8FAFC",
                  fontWeight: 700,
                  lineHeight: "28px",
                }}
                data-test-id='technical-requirement-title'
              >
                Technical Requirements
              </Typography>
              <IconButton aria-label="close" data-test-id='close-desc-Button' onClick={this.handleDescModalClose} style={{
                padding: '0px'
              }}>
                <CloseIcon style={{
                  color: "#fff"
                }} />
              </IconButton>
            </Box>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              padding: "32px 24px",
            }}>
              <Box style={{
                color: "#F1F5F9",
                fontSize: "14px",
                lineHeight: "18px",
                fontWeight: 400
              }}>
                {this.state?.screenSelected?.attributes?.media_format.toLowerCase() === "image" ? 
                <ul style={{
                  margin: "0px",
                  paddingLeft: "22px" 
                }}>
                  <li style={{
                    marginBottom: "10px",
                  }} data-test-id="formats-supported-text">
                    <strong>Formats Supported:</strong> All formats
                  </li>
                  <li style={{
                    marginBottom: "10px",
                  }}>
                    <strong>Image Size:</strong> Up to 12 MB
                  </li>
                  <li style={{
                    marginBottom: "10px",
                  }}>
                    <strong>Image Dimensions:</strong> Minimum 50x50 pixels
                  </li>
                  <li>
                    <strong>Recommendations:</strong>
                    <ul style={{
                      paddingLeft: "15px" ,
                      margin: "0px",
                    }}>
                      <li style={{
                        marginBottom: "10px",
                      }}>
                        Don't downscale images to less than 300x300 pixels as it might reduce accuracy.
                      </li>
                      <li style={{
                        marginBottom: "10px",
                      }}>
                        Preferred formats for faster processing: WEBP, JPEG, JPEG 2000.
                      </li>
                      <li>
                        For optimized usage, keep images typically less than 1 MB.
                      </li>
                    </ul>
                  </li>
                </ul> 
                : 
                <ul style={{
                  paddingLeft: "22px", 
                  margin: "0px",
                }}>
                  <li style={{
                    marginBottom: "10px",
                  }}>
                    <strong>Formats Supported:</strong> All common video formats like MP4, AVI, MOV, WMV, FLV, WEBM, and MKV (excluding advanced MKV
                      variants).
                  </li>
                  <li style={{
                    marginBottom: "10px",
                  }}>
                    <strong>Video Size:</strong> For larger videos, files up to a 100MB limit are supported.
                  </li>
                  <li style={{
                    marginBottom: "10px",
                  }}>

                    <strong>Video Dimensions and Resolution:</strong>
                    <ul style={{
                      margin: '0',
                      paddingLeft: '15px'
                    }}>
                      <li style={{
                        marginBottom: "10px",
                      }}>
                        Minimum dimensions: 50 pixels in width or height.
                      </li>
                      <li>Recommended resolution: 480p quality is usually enough.</li>
                    </ul>
                  </li>
                  <li><strong>Video Length:</strong> Videos should be less than 60 seconds long.</li>
                </ul>
                }
              </Box>
            </Box>
          </Box>
        </Dialog>

        <Drawer
          open={this.state.showMediaUploadPopUp}
          anchor="bottom"
          className="media-drawer"
          ModalProps={{
            BackdropProps: {
              onClick: this.closeMediaUploadPopUp, 
            },
          }}
        >
          <div>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '8px 24px 0px' }}>
              <Typography
                style={{ fontSize: '0.84em', fontWeight: 400, color: '#3C3C43', textAlign: 'center', padding: '8px' }}
              >
                Camera and Gallery
              </Typography>
              <Divider />
              <input
                data-test-id="camera"
                ref={this.cameraRef}
                type="file"
                accept={this.acceptedInputs()}
                capture="environment"
                style={{ display: "none" }}
                disabled={this.uploadFileDisabled()}
                onChange={(event) => this.manageFileUpload(event, uploadedFiles.length)}
              />

              <Button
                data-test-id="cameraBtn"
                variant="text"
                onClick={() => this.handleCameraClick()}
              >
                Camera
              </Button>
              
              <Divider />
              <input
                ref={this.galleryRef}
                type="file"
                accept={this.acceptedInputs()}
                style={{ display: "none" }}
                disabled={this.uploadFileDisabled()}
                onChange={(event) => this.manageFileUpload(event, uploadedFiles.length)}
              />
              <Button
                data-test-id="galleryBtn"
                variant="text"
                onClick={() => this.handleGalleryClick()}
              >
                Gallery
              </Button>
              <Divider/>
            </div>

            <div className="MuiPaper-root MuiDrawer-paper MuiDrawer-paperAnchorBottom MuiPaper-elevation16" style={{ margin: '8px', borderRadius: '16px' }}>
              <Button
                variant="text"
                onClick={() => this.closeMediaUploadPopUp()}
                data-test-id='cancelBtn'
              >
                Cancel
              </Button>
            </div>
          </div>
        </Drawer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    // background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  alertBox:{
    padding: '8px 12px',
    fontSize: '0.9em',
                borderLeft: '4px solid red',
                backgroundColor: '#FEE2E2',
                color: 'red',
                
                borderRadius: '4px',
                margin:'12px 0',
                width:'90%'
  },
};
const styles = {
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    top: 0,
    zIndex: 9999,
    width: '100%',
  },
  uploadingCard: {
    marginBottom: '16px',
    borderRadius: '8px',
    width: '100%'
  },
  customColor: {
    "& .MuiLinearProgressBar": {
      backgroundColor: "green",
    },

  },
  Paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '27vh',
    margin: 'auto',
    background: 'transparent !important',
    border: '3px dashed #CBD5E1',
    borderRadius: ' 20px !important',
  },
  paperElevation1: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none !important"
    },
  },

  container: {
    // background: 'rgb(238, 243, 248)',
    padding: '16px 16px ',
    // height:'88%'
  },
  child: {
    margin: '8px 0px  80px 0',
  },

  paddingH: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  lsp: {
    lineHeightStep: 'initial',
  },
  alert: {
    marginTop: '10px',
    position: 'relative',
    display: 'flex',
    width: 'auto',
    height: '5vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '2vh 0',
    background: '#fee2e2',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  alertText: {
    fontSize: '18px !important',
    width: '85%',
    color: '#dc2626',
  },
  alertLine: {
    display: 'block',
    height: '200%',
    width: '5px',
    background: '#dc2626',
    marginRight: ' 15px',
  },

};
// Customizable Area End
