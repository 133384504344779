
export const config = {
	// Customizable Area Start
	creatPaymentIntentApiContentType: 'application/json',
	createpaymentIntentEndpoint: 'stripe_integration/payment_intents',
	createPaymentIntentMethod: 'POST',
	PaymentIntentEndPoint: '/bx_block_stripe_integration/payments',

	stripeKey: 'INSERT_KEY_HERE',
	stripeCurrency: 'USD',

	orderId: 'Order ID',
	loading: 'Loading...',
	cancelText: 'Cancel',
	submitText: 'Submit',

	stripeSuccessMessage: 'Successfully communicated with Stripe',
	stripeErrorMessage: 'There has been an error'
	// Customizable Area End
};
export const checkNetworkConnectivity = (handleError: (message: string)=>void) =>{
	if (!navigator.onLine) {
		handleError("No internet connection. Please check your network.");
		throw new Error("No internet connection");
	}
}
