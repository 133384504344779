import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import {GenericApiCall} from '../../utilities/src/GenericApiCall'

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  isFormSubmit:boolean;
  errorMsg:string;
  accountDeleted:boolean;
  roleId: string | number | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailLoginApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  messageInetrval:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.LoginSuccessMessage)
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      isFormSubmit: false,
      errorMsg:'',
      accountDeleted:false,
      roleId:'',
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    let userData:any = localStorage.getItem("userData")
    userData = userData ? JSON.parse(userData) : null;
    let accountStatus = localStorage.getItem('acc_Status')
    localStorage.setItem("fullName", "")
    localStorage.setItem("email", "")
    localStorage.setItem("numSecurity", "")
    // Clear previous intervel if it exists
  if (this.messageInetrval) {
    clearInterval(this.messageInetrval);
}
// Set new interval to remove error message
this.messageInetrval = setTimeout(() => {
    this.setState({ accountDeleted: false });
    localStorage.removeItem("acc_Status");
}, 5000);
    this.setState({accountDeleted:accountStatus? true : false})
    if(userData && userData.isRemember){
      this.setState({checkedRememberMe:userData.isRemember,email:userData.userEmail,password:userData.userPassword})
    }
    window.history.pushState(null, 'null', window.location.pathname);
    window.addEventListener('popstate', (event) => {
      console.log("event") ;
         window.history.pushState(null, 'null', window.location.pathname);
    });
    // Customizable Area End
  }

  // Customizable Area Start
  
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
     if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log("errrr",errorReponse);
      this.setState({ errorMsg: errorReponse || "" })

      if(apiRequestCallId == this.emailLoginApiCallId){
        this.handleSuccessLogin(responseJson)
      }
    }
   
    // Customizable Area End
  }
  // Customizable Area Start
  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  handleSuccessLogin(responseJson: any) {
    if (responseJson && responseJson.message) {
      this.setState({ errorMsg: responseJson.message || "" })
    }
    else if (responseJson && responseJson.errors) {
      let msg: string[] = Object.values(responseJson.errors[0])
      this.setState({ errorMsg: msg[0] })
    }
      else if (responseJson?.meta) {   
        this.setState({ roleId: responseJson.meta.role_id }, () => {
          let nextPage = "HomeDashboard";
          let userData = { 
            userEmail : this.state.email, 
            userPassword: this.state.password, 
            isRemember: this.state.checkedRememberMe, 
            role_id: responseJson.meta.role_id, 
            roleName:responseJson.meta.role_name
          };
          localStorage.setItem("userData",JSON.stringify(userData))
          localStorage.setItem("token",responseJson.meta.token)
          const navigatedFromUrlScreen = this.props.navigation.getParam('screenid');

          if(responseJson.meta.role_name === 'screen_manager'){
            nextPage = "AdManager";
          };
          if (navigatedFromUrlScreen) {
            localStorage.setItem("scannedQrValue", navigatedFromUrlScreen);
            nextPage = "PaymentScreen";
          };
          const msg: Message = new Message(
            getName(MessageEnum.NavigationMessage)
          );
          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          msg.addData(getName(MessageEnum.NavigationTargetMessage), nextPage);
          this.send(msg);
        });
      }
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const info = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: info,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  naviagteToSignUp=()=>{
    if(this.props.navigation.getParam('screenid')) this.props.navigation.navigate('SignuploginBlockUploadScreen', {screenid: this.props.navigation.getParam('screenid')})
    else {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Signuplogin");
    this.send(msg);
    }
  }
  onLogin=(event:any)=>{
    event.preventDefault()
    this.setState({ isFormSubmit: true })
    if(!this.state.email || !this.state.password){
      this.setState({errorMsg:"Email & Password are mandatory for Log in"})
      return false
    }
   else{ 
    let httpBody = {
      "data": {
        "type": "email_account",
        "attributes": {
          "email": this.state.email,
          "password": this.state.password,
        }
      }
    }
    let requestMesg = GenericApiCall(configJSON.loginApiContentType, configJSON.loginAPiMethod, configJSON.loginApiEndPoint, '', JSON.stringify(httpBody))
    this.emailLoginApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }
  }
  navigateToLink=(navigationLink:string)=>{
    const linkMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    linkMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    linkMsg.addData(getName(MessageEnum.NavigationTargetMessage), navigationLink);
    this.send(linkMsg);
  }
  // Customizable Area End
}
