import React, { ReactElement } from 'react';
import { createStyles, withStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Chip,
    IconButton,
    MenuItem,
    ListItemIcon,
    ListItemText,
    styled,
    CircularProgress,
} from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';

import FilterListIcon from '@material-ui/icons/FilterList';
import MoreVert from '@material-ui/icons/MoreVert';
import { MdOutlineCheckCircle } from "react-icons/md";
import { CgMenuGridO } from "react-icons/cg";
import { BiCloudUpload } from "react-icons/bi";
import { LuMonitorOff } from "react-icons/lu";


type headerKeyTypes = {
    title: string;
    key: string;
    dataKey: string;
}
type objectDataType = {[key:string]: any};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      color: "#94A3B8"
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(0),
      color: "#94A3B8",
      boxShadow: 'none'
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    iconColor: { color: "#94A3B8" },
    svg: {
      color: "#94A3B8",
      height: "25px",
      width: "25px",
    },
    tableDataRow: {
      background: "#01010180",
      borderBottom: "1px solid #334155",
    },
    tableHeader: {
      background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
    },
    headerStyles: {
      padding: '10px 8px',
      background: 'transparent'
    },

  }),
);

interface EnhancedTableProps {
    headerCol: headerKeyTypes[];
    showFilter: boolean;
    handleHeaderFilter: (key: string) => void;
}

const headerFilterData = [{
  icon: <CgMenuGridO />,
  title: 'All',
  key: 'all'
}, {
  icon: <BiCloudUpload />,
  title: 'Active',
  key: 'active'
}, {
  icon: <MdOutlineCheckCircle />,
  title: 'Available',
  key: 'available'
}, {
  icon: <LuMonitorOff />,
  title: 'Unavailable',
  key: 'unavailable'
}]

function EnhancedTableHead(props: EnhancedTableProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = (key: string) => {
    props.handleHeaderFilter(key);
    handleClose();
  }

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow className={classes.tableHeader}>
        {props.headerCol.map((headCell) => headCell.key !== "action" ? (
          <TableCell
            key={headCell.key}
            className={classes.headerStyles}
            style={{fontWeight: 'bold'}}
            align='left'
          >
              {headCell.title}
          </TableCell>
        ) : (
            <TableCell key={headCell.key} padding={'normal'} align='right' className={classes.headerStyles}>
                {props.showFilter && (
                  <>
                    <IconButton
                      aria-haspopup="true"
                      onClick={handleMenuClick}
                    >
                      <FilterListIcon  className={classes.iconColor} />
                    </IconButton>
                    <StyledMenu
                      id="customized-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {headerFilterData.map((item, index) => {
                        return (  
                          <StyledMenuItem 
                            key={`${item.key}-${index}`} 
                            onClick={() => handleFilter(item.key)}
                          >
                            <ListItemIcon style={{minWidth: "35px"}}>
                              {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                          </StyledMenuItem>
                        )
                      })}
                    </StyledMenu>
                  </>
                )}
            </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


type TablePropTypes = {
    headerKeys: headerKeyTypes[];
    tableData: any[];
    showFilter: boolean;
    moreMenuList: {title: string, key: string, icon: ReactElement}[];
    isLoading?: boolean;
    handleMoreItem?: (key: string, data: objectDataType) => void;
    handleHeaderFilter?: (key: string) => void;
}

type AnyObject = { [key: string]: any };
export function get(obj: any, key: string) {
    const keys = key.split('.');
    return keys.reduce((acc, curr, index) => {
        if (Array.isArray(acc)) {
          acc = acc.map(item => item[curr]);
          acc = acc.filter((val: AnyObject) => val !== undefined);
          if (keys[index] === 'files') {
              acc = acc.filter((item: AnyObject) => item.status === 'published');
          }
          return acc.length > 0 ? acc[0] : '';
        } else if (acc && acc.hasOwnProperty(curr)) {
            return acc[curr];
        } else {
            return '';
        }
    }, obj);
}

export default function EnhancedTable(props: TablePropTypes) {
  const classes = useStyles();
  const {tableData, isLoading, handleMoreItem} = props;
  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {

  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currantRowData, setCurrantRowData] = React.useState<objectDataType>({});

  const emptyRows = props.tableData.length;
  const getChipColor = (value: string) => {
    if(value === 'unavailable') return {width: '115px', backgroundColor: "#FEE2E2", color: "#F00"}
    if(value === 'available') return {width: '115px', backgroundColor: "#FEF3C7", color: "#D97706"};
    if(value === 'active') return {width: '115px', backgroundColor: "##D1FAE5", color: "#059669"};
    return {display: 'none'}
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, rowData: objectDataType) => {
    setAnchorEl(event.currentTarget);
    setCurrantRowData(rowData);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrantRowData({});
  };


  const handleMenuItemClick = (key: string) => {
    handleMoreItem && handleMoreItem(key, currantRowData);
    handleClose();
  }

  const handleHeaderFilter = (key: string) => {
    props.handleHeaderFilter && props.handleHeaderFilter(key)
  }

  return ( 
    <Table
      // size={'medium'}
      stickyHeader
    >
      <EnhancedTableHead 
        headerCol={props.headerKeys} 
        showFilter={props.showFilter} 
        handleHeaderFilter={handleHeaderFilter}
      />
      <TableBody>
          {tableData.map((row) => {
              return (
                <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    key={row.id}
                    tabIndex={-1} 
                    className={classes.tableDataRow}
                >
                  {props.headerKeys.map((header) => header.key !== "action" ? (
                      <TableCell key={header.key} style={{fontSize: '12px'}} className={classes.headerStyles}>
                        {
                          ((header.key === 'screen_name' || header.key === 'file_name') && get(row, header.dataKey)?.length > 8) ? `${get(row, header.dataKey).slice(0, 8)}...` :
                          (header.key === 'rate') ? get(row, header.dataKey)?.length > 3 ? `$${get(row, header.dataKey).toString().slice(0, 3)}...` : `$${get(row, header.dataKey)}` :
                          header.key === "status" ? (
                            <Chip style={getChipColor(get(row, header.dataKey))} label={get(row, header.dataKey)?.toUpperCase()} />
                          ) : (
                            get(row, header.dataKey)
                        )}
                      </TableCell>
                  ) : (
                    <TableCell key={header.key} padding={'normal'} align='right' style={{fontSize: '12px', padding: '0px 16px'}}>
                      <IconButton
                        aria-haspopup="true"
                        onClick={(event) => handleMenuClick(event, row)}
                      >
                        <MoreVert className={classes.iconColor} />
                      </IconButton>
                      <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        {props.moreMenuList.map((menu) => (currantRowData?.attributes?.status === 'unavailable' && menu.key === 'upload') ? null : (
                          <StyledMenuItem key={menu.key} onClick={() => handleMenuItemClick(menu.key)}>
                            <ListItemIcon style={{minWidth: "35px"}}>
                              {menu.icon}
                            </ListItemIcon>
                            <ListItemText primary={menu.title} />
                          </StyledMenuItem>
                        ))}
                      </StyledMenu>
                    </TableCell>
                  ))}
                </TableRow>
              );
          })}
        {!isLoading && !emptyRows && (
          <TableRow style={{ height: (53) * emptyRows }} className={classes.tableDataRow}>
            <TableCell colSpan={6} align='center'>
              No data
            </TableCell>
          </TableRow>
        )}
        {isLoading && (
          <TableRow style={{ height: (53) * 2 }}>
            <TableCell colSpan={6} align='center'>
              <CircularProgress className={classes.iconColor} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

const StyledMenu = withStyles({
  paper: {
    background: "#000",
    color: "#94A3B8",
    borderRadius: "8px"
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = styled(MenuItem)({
  color: '#94A3B8',
  minWidth: '180px',
  '& svg': {
    color: '#94A3B8',
    height: '25px',
    width: '25px',
  },
});