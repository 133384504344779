import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData } from "framework/src/Utilities";
import DevicesIcon from '@material-ui/icons/Devices';

import {
  FaArrowLeft,
} from 'react-icons/fa';

import {
  AppBar,
  Box,
  Typography,
  styled,
  Divider,
  TableContainer,
	Toolbar,
} from '@material-ui/core';
import { MdOutlineDvr } from "react-icons/md";
import FileTable from '../../../components/src/commonTable/FileList';
const configJSON = require('../config');


const moreMenu = [
  {
    title: "Ad Details",
    key: 'Ad_details',
    icon: <MdOutlineDvr style={{width: '20px'}} />
  },
	{
    title: "View Ad",
    key: 'View_ad',
    icon: <DevicesIcon style={{width: '20px'}} />
  }
]

export interface Props {
	navigation: any;
}
interface S {
	anchorEl: null | HTMLElement;
	headerAnchorEl: null | HTMLElement;
	adList: object[];
	adListLoader: boolean;
	openDescModal: boolean;
	displayAlert: boolean;
	removeData: boolean;
	removeAdLoader: boolean;
}
interface SS {
	navigation: any;
}


export default class ManagerAdList extends BlockComponent<Props, S, SS> {

  getAdListApiID: string = '';

  constructor(props: Props) {
    super(props);

		this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),

    ];

		this.state = {
      anchorEl: null,
      headerAnchorEl: null,
      adList: [],
      adListLoader: false,
      openDescModal: false,
      displayAlert: false,
      removeData: false,
      removeAdLoader: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

	async componentDidMount() {
		this.getAdListList();
	}

	getAdListList = async() => {
    this.setState({adListLoader: true})
		const userId = this.props.navigation.getParam('userId');

		this.getAdListApiID = await this.apiCall({
			method: configJSON.httpGetMethod,
			contentType: configJSON.validationApiContentType,
			endPoint: `${configJSON.getUsersAdListApiEndPoint}${userId}`
		})
	}

	apiCall = async (data: {
    contentType: string;
    method: string;
    endPoint: string;
    bodyData?: {[key: string]: string | number}
  }) => {
    const { contentType, method, endPoint, bodyData } = data;
    const token = await getStorageData("token");
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);

    bodyData && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyData));

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

	async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      runEngine.debugLog("API Message Recived", message);

      if(apiRequestCallId === this.getAdListApiID){
        this.setState({
          adList: responseJson.data || [],
          adListLoader: false
        });
      }
    }
  }

	goBack = () => {
		this.props.navigation.goBack()
	}

  detailScreenHeader = () => {
    const userName = this.props.navigation.getParam('userName')
    return (
      <DetailScreenHeader>
        <FaArrowLeft data-test-id="go-back" onClick={this.goBack} />
				<h1 style={webStyle.headerTitle}>{userName?.length > 17 ? `${userName.slice(0, 17)}...` : userName}</h1>
      </DetailScreenHeader>
    );
  }

  renderScreens = () => {
    return (
      <>
        <ScreenBox id="file-list-screen">
          <Typography className="rsTitle">
            {this.state.adList?.length} {"results"}
          </Typography>
          <CustomDivider variant="middle" />
					<TableBox>
						<UpdatedTableContainer>
							<FileTable
								data-test-id="table"
								headerKeys={configJSON.filesListHeaderKeys}
								tableData={this.state.adList}
								showFilter={false}
								moreMenuList={moreMenu}
								isLoading={this.state.adListLoader}
								navigation={this.props.navigation}
							/>
						</UpdatedTableContainer>
					</TableBox>
        </ScreenBox>
      </>
    );
  };

  render() {

    return (
      <>
				
        <Box style={{
					background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
				}}>
					<Toolbar>
						{this.detailScreenHeader()}
					</Toolbar>
					<MainContent>
						{this.renderScreens()}
					</MainContent>
        </Box>
			</>
    );
  }
}

const webStyle = {
  headerTitle: {
    fontFamily: 'Inter',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
    'text-align': 'left',
    color: '#FFF',
    marginTop: '15px',
    marginBottom: '10px',
  },
};


const DetailScreenHeader = styled(AppBar)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'centner',
  padding: '20px 16px 16px 20px',
  color: '#FFF',
	boxShadow: "none",
  zIndex: 999,
  paddingBottom: '0px',
  borderBottom: "1px solid #64748B",
  background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'
});

const MainContent = styled(Box)({
	marginTop: '50px',
  background: "linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)",

});

const ScreenBox = styled(Box)({
  '& .rsTitle': {
    textTransform: 'uppercase',
    paddingLeft: '16px',
    color: '#94A3B8',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '18px',
    marginTop: '8px',
    marginBottom: '8px',
  },
  display: 'flex',
  flexDirection: 'column',
});

const CustomDivider = styled(Divider)({
  backgroundColor: '#94A3B8',
  height: '1px',
});

const TableBox = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '80%',

  '& .MuiPaper-root': {
    background: 'transparent',
		display: 'flex',
    flexDirection: 'column',
  },
  '& .MuiTableCell-root': {
    border: 'none',
  },
  '& .MuiTableCell-head': {
    color: '#94A3B8',
  },
  '& .MuiTableCell-body': {
    color: '#94A3B8',
  },
  '& .table-data-row': {
    background: '#01010180',
    borderBottom: '1px solid #334155',
  },
});

const UpdatedTableContainer = styled(TableContainer)({
  flexGrow: 1,
	overflow: 'auto',
})

