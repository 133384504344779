import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import videojs from "video.js";

// Customizable Area Start

import React from "react";
import { createConsumer } from "@rails/actioncable";

const baseURL = require("../../../framework/src/config");
let consumer: any = null;

const getCable = (): any => {
  if (!consumer) {
    const url = 'wss' + baseURL.baseURL.replace("https", "") + "/cable"

    consumer = createConsumer(url);
  }
  return consumer;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  current: number;
  prev:number
  screenAssetsData: any;
  intervalId: any;
  assetType: string;
  qrCode: string;
  isUnmuted: boolean;
  loader: boolean;
  error: string;
  randomKey: number;
  showButton: boolean;
  videoLoader: boolean;
  opacity: number
  duration: number
  interactive: boolean
  showQRCode: boolean
  imgLoading: boolean
  hasRetried: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfonplatformscreencreation0Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  screenAssetsApiCallId: string = "";
  videoRef: any;
  preloadRef: any;
  preloadRef1: any
  loaderRef: any
  socket: any;
  subscription: any;
  interval: any;
  timeoutId: NodeJS.Timeout | null = null;
  intervalId: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      current: 0,
      prev:0,
      screenAssetsData: [],
      intervalId: "",
      assetType: "",
      qrCode: "",
      isUnmuted: false,
      loader: true,
      error: "",
      randomKey: 0,
      showButton: true,
      videoLoader: false,
      opacity: 1,
      duration: 20,
      interactive: false,
      showQRCode: false,
      imgLoading: false,
      hasRetried: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.videoRef = React.createRef();
    this.loaderRef = React.createRef();
    this.subscription = "";
    this.setupSocket = this.setupSocket.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.screenAssetsApiCallId) {
          this.setState({ loader: false });
          if (!responseJson.errors) {
            this.handleCurrentScreenAssets(responseJson);
          } else {
            if (responseJson.errors) {
              this.setState({ error: responseJson.errors, qrCode: "", assetType: "", screenAssetsData: [] });
            } else {
              this.setState({ error: "Something went wrong!", qrCode: "", assetType: "", screenAssetsData: [] });
            }
          }
        }
      }
    }
    // Customizable Area End
  }


  handleCurrentScreenAssets = (responseJson: any) => {
    const screenId = this.props.navigation.getParam('uniqueid');
    if (responseJson.data.qr_code && !responseJson.data.assets?.length) {
      this.setState({ qrCode: responseJson.data.qr_code, screenAssetsData: "", assetType: "", error: "" })
    } else if (responseJson.data.assets.length) {
        const assets = responseJson.data.assets.map((item: any) => item.url);
        let storedData = JSON.parse(localStorage.getItem("currentScreens") || "{}");
        if (this.state.screenAssetsData.length > 0) {
          if (responseJson.data.assets[0].media_type.includes("image")) {
            if(this.state.interactive && this.state.showQRCode){
              this.setState(
                (prevState) => ({
                  screenAssetsData: assets,
                  showQRCode:false,
                  imgLoading: true,
                  opacity:0,
                  prev:Math.min(prevState.current+1, assets.length - 1),
                  current: Math.min(prevState.current+1, assets.length - 1),
                }),()=>{
                  storedData[screenId] = this.state.current;
                  localStorage.setItem("currentScreens", JSON.stringify(storedData));
                }
              );
            }else{
              this.setState({screenAssetsData: assets})
            }
          }else{
            this.setState({screenAssetsData:assets},()=>{
              if (this.state.showQRCode && this.state.interactive) {
                
                this.setState(prevState=>({ 
                    showQRCode: false, 
                    current: Math.min(prevState.current+1, prevState.screenAssetsData.length - 1) // Keep index in bounds
                }), () => {
                    if (this.videoRef.current) {
                        this.videoRef.current.src = this.state.screenAssetsData[this.state.current]; // Set the last played video
                        this.videoRef.current.load();
                        storedData[screenId] = this.state.current;
                        localStorage.setItem("currentScreens", JSON.stringify(storedData));
                        this.videoRef.current.play();
                    }
                });
            }
            })
            
          }
          
        } else {
            if (responseJson.data.assets[0].media_type.includes("image")) {
              const durationStr = responseJson.data.duration;
              const durationParts = durationStr.split(":");
          
              const hoursInSeconds = parseInt(durationParts[0], 10) * 3600;
              const minutesInSeconds = parseInt(durationParts[1], 10) * 60;
              const seconds = parseInt(durationParts[2], 10);
              
              const totalDurationInSeconds = hoursInSeconds + minutesInSeconds + seconds;
              const intervalTime = totalDurationInSeconds > 60 ? 60000 : totalDurationInSeconds * 1000;
              let currentIndex = 0; 
              let imgLoading = true
              if(responseJson.data.interactive){
                currentIndex = storedData[screenId] || 0;
                this.setState({prev:currentIndex})
                if(currentIndex > assets.length-1){
                  imgLoading=false
                  this.setState({showQRCode: true})
                }
              }
              this.setState({ screenAssetsData: assets, assetType: responseJson.data.assets[0].media_type, current: currentIndex, interactive:responseJson.data.interactive, qrCode: responseJson.data.qr_code, error: "",duration: intervalTime, imgLoading });
            } else {
              let currentIndex = 0;
              let videoLoader = true 
              if(responseJson.data.interactive){
                currentIndex = storedData[screenId] || 0;
                if(currentIndex > assets.length-1){
                  videoLoader=false
                  this.setState({showQRCode: true})
                }
              }
              this.setState({ screenAssetsData: assets, assetType: responseJson.data.assets[0].media_type, current: currentIndex, interactive:responseJson.data.interactive, qrCode: responseJson.data.qr_code, error: "", videoLoader });
            }
          }
      }
  }

  startSlideshow = () => {
    const { duration, interactive } = this.state; 
    const screenId = this.props.navigation.getParam('uniqueid');
    let storedData = JSON.parse(localStorage.getItem("currentScreens") || "{}");

    const intervalId = setInterval(() => {
        this.setState(prevState => {
            const nextIndex = prevState.current + 1;

            if (nextIndex >= this.state.screenAssetsData.length) {
                if (interactive) {
                    clearInterval(intervalId);
                    storedData[screenId] = nextIndex;
                    localStorage.setItem("currentScreens", JSON.stringify(storedData));
                    return { showQRCode: true, current: prevState.current,prev:prevState.current, intervalId: null,imgLoading:false };
                } 

                clearInterval(intervalId);

                setTimeout(() => {
                    const newIntervalId = this.startSlideshow(); 
                    this.setState({ current: 0,prev:0, showQRCode: false, intervalId: newIntervalId, imgLoading:true});
                }, duration);

                return { showQRCode: true, current: prevState.current,prev:prevState.current, intervalId: null, imgLoading:false};
            }
            storedData[screenId] = nextIndex;
            localStorage.setItem("currentScreens", JSON.stringify(storedData));
            return { current: nextIndex,prev:prevState.current, showQRCode: false, intervalId: prevState.intervalId, imgLoading: true };
        });
    }, duration);

    this.setState({ intervalId, showQRCode: false });
    return intervalId;
  };

  handleImageLoad = () => {
      this.setState({ imgLoading: false }, () => {
        clearInterval(this.state.intervalId);
        this.startSlideshow();
      });
  };

  setupSocket() {
    const cable = getCable();
    const getScreen = () => {
      this.setState({ randomKey: Math.random() });
    }

    this.subscription = cable.subscriptions.create(
      { channel: "ScreenStatusChannel" },
      {
        connected() {
          console.log("Connected to the chat room");
        },
        received(data: any) {
          console.log("daata room", data);
          if (data.status === "active" || data.status === "published" || data.status === 'deleted') {
            getScreen();
          }
        },
        consumer() {
          console.log("daata room");
        },
        disconnected() {
          console.log("Disconnected from the chat room");
        },
      }
    );
  }

  async componentDidMount(): Promise<void> {
    this.getScreenAssets();
    this.setupSocket();
    const timeUntilMidnight = this.calculateTimeUntilMidnightEST();

    this.timeoutId = setTimeout(() => {
      this.getScreenAssets();

      this.intervalId = setInterval(() => {
        this.getScreenAssets();
      }, 24 * 60 * 60 * 1000);
    }, timeUntilMidnight);

  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.state.randomKey !== prevState.randomKey) {
      this.getScreenAssets();
    }
  };

  async componentWillUnmount(): Promise<void> {
    this.subscription.unsubscribe();
    if (this.state.intervalId) {
      clearInterval(this.interval);
    }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  handleVideoEnd = () => {
    const screenId = this.props.navigation.getParam('uniqueid');
    const { current, screenAssetsData, interactive, duration } = this.state;
    const isLastVideo = current === screenAssetsData.length - 1;
    let storedData = JSON.parse(localStorage.getItem("currentScreens") || "{}");
    if (isLastVideo) {
      // Show QR code
      if(this.state.interactive){
        storedData[screenId] = current+1;
        localStorage.setItem("currentScreens", JSON.stringify(storedData));    
      }
      this.setState({ showQRCode: true });
  
      if (!interactive) {
        // Restart videos after duration if interactive is false
        setTimeout(() => {
          this.setState({ showQRCode: false, current: 0 }, () => {
            if (this.videoRef.current) {
                this.videoRef.current.src = screenAssetsData[0]+ "?t=" + new Date().getTime();
                this.videoRef.current.load();

                this.videoRef.current.onloadedmetadata = () => {
                    this.videoRef.current.play();
                };
            }
          });
        }, duration*1000);
      }
    } else {
      // Move to next video
      const nextIndex = current + 1;
      this.setState({ opacity: 0 });
      setTimeout(() => {
        if (this.videoRef.current) {
            setTimeout(()=>{
              this.videoRef.current.src="";
            this.videoRef.current.pause()
            this.videoRef.current.src = screenAssetsData[nextIndex].replace(".mp4","_low.mp4") + "?t=" + new Date().getTime();
            this.videoRef.current.onloadedmetadata = null;
            
            // ✅ Use `onloadeddata` instead of `oncanplaythrough`
            this.videoRef.current.onloadedmetadata = () => {
              this.setState({ current: nextIndex, opacity: 1 });
              storedData[screenId] = nextIndex;
              localStorage.setItem("currentScreens", JSON.stringify(storedData));
              // Ensure video starts after it fully loads
              setTimeout(() => {
                  this.videoRef.current.play();
              }, 300);
          };
            },50)
        }
      }, 400);
    }
  };
  

  handleCanPlayThrough = async () => {
    console.log("✅ Video can play now!");
  
    this.setState({ videoLoader: false, hasRetried: false });
  
    if (this.videoRef.current) {
      this.videoRef.current.muted = this.state.isUnmuted ? false : true;
  
      if (this.videoRef.current.paused) {
        setTimeout(() => this.videoRef.current.play(), 100); // Add slight delay
      }
    }
  } 

  handleLoadStart = async () => {
    if(!this.state.videoLoader){
      this.setState({ videoLoader: true });
    }
  };

  getScreenAssets() {
    this.setState({ loader: true })
    const screenId = this.props.navigation.getParam('uniqueid');
    const headers = {
      "Content-Type": configJSON.screenAssetsApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.screenAssetsApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.screenAssetsAPiEndPoint + "3c92b59c45ea366a3f71342"
      // configJSON.screenAssetsAPiEndPoint + "7fbd54e706a273a3253b341"
      configJSON.screenAssetsAPiEndPoint + screenId
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.screenAssetsApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  // web events

  // Customizable Area Start
  
  calculateTimeUntilMidnightEST = () => {
    const now = new Date();

    const estNow = new Date(
      now.toLocaleString("en-US", { timeZone: "America/New_York" })
    );

    const midnightEST = new Date(estNow);
    midnightEST.setHours(24, 0, 0, 0);

    const timeUntilMidnight = midnightEST.getTime() - estNow.getTime();

    return timeUntilMidnight;
  };

  handleInitialFetch = () => {
    const now = new Date();

    const estNow = new Date(
      now.toLocaleString("en-US", { timeZone: "America/New_York" })
    );

    if (estNow.getHours() === 0 && estNow.getMinutes() === 0) {
      this.getScreenAssets();
    } else if (estNow.getHours() > 0) {
      this.getScreenAssets();
    }
  };

  handleUnmute = () => {
    if (this.videoRef.current) {
        this.videoRef.current.muted = false;
        this.setState({ isUnmuted: true });
        this.videoRef.current.play();
        setTimeout(() => {
          this.setState({ showButton: false });
        }, 2000);
      
    }
  };

  handleLoadError = (event: any) => {
    if (this.state.assetType.includes("video")) {
      if (!this.state.hasRetried) {
        if (this.videoRef.current) {
          setTimeout(() => {  
            this.videoRef.current.src = this.state.screenAssetsData[this.state.current] + "?t=" + new Date().getTime();
            this.videoRef.current.load(); // ✅ Load first, no play yet
                    setTimeout(()=>{
                this.videoRef.current.play()

                    },200)
          }, 1000);
        }
  
        this.setState({ hasRetried: true });
  
      } else {
        this.setState({
          videoLoader: false,  // Hide loader
          error: "Failed to load video. Please try again later.",
          hasRetried: false  // Reset retry state for next video
        });
      }
    } else {
      const imgElement = document.getElementById(event.target.id) as HTMLImageElement;
      if (imgElement) {
        imgElement.src = "";  // ✅ Remove the broken image source
        imgElement.style.display = "none"; // ✅ Hide the broken image completely
        imgElement.onerror = null;
      }
      clearInterval(this.state.intervalId);
      this.setState({
        imgLoading: false,
        error: "Failed to load image. Please try again later."
      });
    }
  };
  
  // Customizable Area End
}
