import React, { useState } from 'react';

import { 
  Typography,
  IconButton,
  styled,
  Container,
  Grid,
  InputLabel,
  Box,
  TextField,
  Button,
  Drawer,
  InputAdornment,
  ListItem,
  ThemeProvider,
  createTheme,
  Popover,
  Popper,
  PopoverProps
} from '@material-ui/core';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import { ExpandMore , Close as CloseIcon } from "@material-ui/icons";
import * as Yup from "yup";
import { Formik , Form, ErrorMessage} from "formik"
import { ArrowBack } from "@material-ui/icons";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { IBlock } from "../../framework/src/IBlock";
import { getStorageData, setStorageData } from "../../framework/src/Utilities";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from '../../framework/src/RunEngine';
export const configJSON = require("./config");
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import moment from 'moment';

const success_icon = require("../assets/success-icon.png");

interface StyledPopoverProps {
  popoverMarginTop: number;
  isSafari: boolean;
}
interface PopoverWrapperProps extends PopoverProps {
  popoverMarginTop: number;
  isSafari: boolean;
}

export const PopoverWrapper: React.FC<PopoverWrapperProps> = (props) => {
  const { popoverMarginTop, ...otherProps } = props;
  return <Popover {...otherProps} />;
};

export const timeToSeconds = (time: string): number => {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

export const secondsToTime = (duration: number | string): string => {
  if (typeof duration === "string") {
    const match = duration.match(/T(\d{2}):(\d{2}):(\d{2})/);
    if (match) {
      const hours = parseInt(match[1], 10);
      const minutes = parseInt(match[2], 10);
      const seconds = parseInt(match[3], 10);
      duration = hours * 3600 + minutes * 60 + seconds;
    } else {
      return "-";
    }
  }

  const totalSeconds = typeof duration === "number" ? duration : parseFloat(duration);
  if (isNaN(totalSeconds) || totalSeconds < 0) {
    return "-";
  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = Math.floor(totalSeconds % 60); // Ensuring whole number seconds

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
};

export const timestampToTime = (timestamp: string): string => {
  const date = moment.parseZone(timestamp);

  return date.isValid() ? date.format("HH:mm:ss") : '-';
};


const styles = {
  paper: {
    width: '100%',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  svg: {
    color: "#94A3B8",
    height: "25px",
    width: "25px",
  },

  form: {
    marginTop: '10px',
    marginBottom: 0,
    width: '100%',
  },
  regionForm: {
    marginTop: '10px',
    marginBottom: 0,
    width: '100%',
    backgroundColor: "black",
  },
  submit: {
    color: "white",
  },
  inputFieldTitle: {
    fontSize: '14px',
    fontWeight: '700',
    margin: '0 0 4px',
    color: '#F1F5F9',
    lineHeight: '22px',
    marginTop: '15px',
  },
  inputFieldValue: {
    color: '#CBD5E1',
    backgroundColor: 'transparent',
    borderRadius: '8px',
    fontFamily: 'Inter',
    border: '1px solid #64748B',
    fontSize: '16px'
  },
  inputFieldModelValue: {
    color: '#E2E8F0',
    backgroundColor: 'transparent',
    borderRadius: '8px',
    fontFamily: 'Inter',
    border: '1px solid #64748B',
  },
  successStatusWrapper: {
    width: "90%",
    display: "flex",
    justifyContent: "center",
    margin: 'auto'
  },
  successStatus: {
    maxWidth: "300px",
    padding: "10px",
    display: "flex",
    margin: "0 auto",
    color: "#0F172A",
    fontSize: "16px",
    lineHeight: "24px",
    // top: '57vh',
    width: 'calc(100vw - 80px)',
    borderRadius: "8px",
    backgroundColor: "#FFF",
    '& img': {
      marginRight: "8px",
      width: "24px"
    },
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'center',
    bottom: 40
  },
  autoCompleteStyle: {
    margin: '0 0',
    border: '1px solid rgb(100, 116, 139)',
    borderRadius: '8px',
    "& :disabled": {
      opacity: 0.6,
      color: "#94A3B8",
    }
  },
  enterCardDetails: {
    fontSize: "14px",
    fontWeight: 'bold',
    lineHeight: "22px",
    color: "#F8FAFC",
    'text-align': "center",
    height: "22px",
    'text-transform': 'uppercase',
  },
  container: {
    background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
    padding: '16px 24px ',
    paddingBottom: '140px',
    zIndex: 0
  },
  value: {
    margin: '5px 0 20px',
    fontSize: '1em',
    color: "#94A3B8"
  },
  buttonArea: {
    bottom: '18px',
    width: '100%',
    maxWidth: 'calc(100vw - 45px)',
  }
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiCollapse: {
      root: {
        '&.alertCollapse': {
          position: "fixed",
          bottom: '8vh',
          padding: "0 20px",
          display: 'flex',
          justifyContent: 'center'
        },
        '&.alertCollapse > .MuiCollapse-wrapper-116': {
          width: '80% !important'
        },
        '&.alertCollapse > .MuiCollapse-wrapper-116 > .MuiCollapse-wrapperInner-117': {
          width: '84%'
        },
        '&.alertCollapse > .MuiCollapse-wrapper-116 > .MuiCollapse-wrapperInner-117 > div': {
          backgroundColor: 'white',
          padding: '8px 16px',

          borderRadius: '8px',
          color: '#0F172A',
          fontSize: '1em',
          boxShadow: '0px 4px 7px grey',
        }
      }
    },
    MuiTypography: {
      'root': {
        color: "#E2E8F0"
      },
    },
    MuiContainer: {
      'root': {
        padding: '0px !important',
        background: "linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)",
        height: '100vh'
      }
    },
    MuiSelect: {
      'root': {}
    },
    MuiInputBase: {
      'root': {
        borderBottom: '0px !important',
        '&.MuiInput-underline,&.MuiInput-underline:before,&.MuiInput-underline:after': {
          borderBottom: '0px !important',

        },

      },

    },
    MuiSvgIcon: {
      'root': {
        ' &.MuiNativeSelect-icon': {
          right: '12px'
        }
      }
    },
    MuiNativeSelect: {
      'root': {
        border: '1px solid rgba(203, 213, 225, 1)',
        borderRadius: '8px !important',
        padding: '12px',
        color: "#94A3B8",
        backgroundColor: 'transparent',
        '&.MuiNativeSelect-select:focus': {
          backgroundColor: '#000',

        }
      },

    },
    MuiButton: {
      root: {
        textTransform: 'none',
        height: '3.2em',
        '&.paymentBtn': {
          background: 'linear-gradient(135deg, rgba(140,139,139,1) 7%, rgba(24,23,25,1) 100%);',
        },
        '&.cancelBtn': {
          color: 'black'
        },
        '&.enabledButton': {
          background: 'linear-gradient(135deg, rgba(140,139,139,1) 7%, rgba(24,23,25,1) 100%);',
        },
        '&.disabledButton,&.disabledButton:hover ': {
          background: 'rgba(104, 104, 104, 1)'

        },
        '&$disabled': {
          background: 'rgba(104, 104, 104, 1) !important'

        },
        fontSize: '1em',
        fontWeight: 700
      }
    },
    MuiFormControl: {
      'root': {
        width: '100%',
        height: '44px',
        justifyContent: 'center'
      }
    },
    MuiOutlinedInput: {'notchedOutline': { border: "none"}} 
  }
});

const StyledPopper = styled(Popper)({
  top: "-5px !important" ,
  border: "2px solid black",
  borderRadius: "8px",
  background: "black"
});

const validationSchema = Yup.object({
  region: Yup.string().required("Region is required to create new screen").nullable(false).typeError("Region must be added to create new screen"),
  interactive: Yup.string().required("Interactive data is required to add new screen").nullable(false).typeError("Interactive must be added"),
  availability: Yup.string().required("Screen availability data is required to add new screen").nullable(false).typeError("Screen Availability must be added"),
  screenName: Yup.string()
    .required("Screen name is required")
    .min(1, 'Screen name must be at least 1 character long')
    .max(30, 'Screen name must be at most 30 characters long')
    .matches(/^[a-zA-Z0-9 ]+$/, 'Screen name cannot contain special characters')
    .test('no-only-spaces', 'Screen name is required', value => value?.trim()?.length > 0),
  mediaFormate: Yup.string().required("Media format is required"),
  screenID: Yup.string().required("Screen id is required").matches(/^[0-9]+$/, "Screen id must be number").matches(/^[0-9]{1,8}$/, 'Screen id must be 8 char long'),
 
  screenFirst: Yup.number()
    .required('Screen width is required')
    .max(7680, 'Screen Width must be at most 7680')
    .min(240, 'Screen Width must be at least 240')
    .typeError('Screen Width must be a number'),
  
  screenLast: Yup.number()
    .required('Screen height is required')
    .max(4320, 'Screen height must be at most 4320')
    .min(320, 'Screen height must be at least 320')
    .typeError('Screen height must be a number'),

  latitude: Yup.number()
    .min(-90, 'Latitude must be at least -90')
    .max(90, 'Latitude must be at most 90')
    .typeError('Latitude must be a number')
    .nullable(),
  
  longitude: Yup.number()
    .min(-180, 'Longitude must be at least -180')
    .max(180, 'Longitude must be at most 180')
    .typeError('Longitude must be a number')
    .nullable(),

  price: Yup.number()
    .required("Price is required")
    .min(0.5, "Price must be at least $0.50").max(999999.99,"Price must be at most $999,999.99")
    .typeError('Price must be a number')
    .test('max-decimals', 'Price can have only two decimal places', value => {
      return /^(\d+(\.\d{1,2})?)?$/.test(value);
    })
    .test('max-length', 'Price cannot exceed 8 digits in total', value => {
      if (value === undefined || value === null) return true;
      const [whole, fraction] = value.toString().split('.');
      const totalLength = whole.length + (fraction?.length || 0);
      return totalLength <= 8;
    }),

  duration: Yup.string()
    .when('mediaFormate', {
      is: 'image',
      then: Yup.string()
        .required('Duration is required for image media format')
        .matches(
          /^([01]?[0-9]|2[0-3]):([0-5]?[0-9]):([0-5]?[0-9])$/,
          'Duration must be in the format HH:mm:ss'
        )
        .test('valid-duration', 'Duration must be at least 1 second for image media format', (value) => {
          const [hours, minutes, seconds] = (value || '')?.split(':')?.map(Number);
          return hours > 0 || minutes > 0 || seconds > 0;
        })
        .typeError('Duration must be a valid string in HH:mm:ss format'),
      otherwise: Yup.string().notRequired()
    }),
  
})

interface regionData {
	id: number;
	name: string;
}

interface ScreenInfo {
  regionId: number;
  region: string;
  screenName: string;
  screenID: string;
  screenFirst: string;
  screenLast: string;
  duration: string;
  latitude: number;
  longitude: number;
  price: string;
  interactive: 'Yes' | 'No' | '';
  mediaFormate: string;
  availability: 'Available' | 'Pause' | 'Remove' ;
  anchorEl: null | HTMLElement;
  regionSearch: string;
  selectedRegion: string;
}

export interface Props {
  navigation: any;
}

interface SS {
  navigation: any;
}

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};


class AddNewScreen extends BlockComponent<Props, any, SS> {
  addScreeCardApiID: string = '';
  getRegionApiID: string = '';
  addRegionApiID: string = '';
  getScreenApiID: string = '';
  getScreenIdApiID: string = '';
  regionInputRef: any;

  constructor(props: any) {
    super(props);

		this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.regionInputRef = React.createRef();
    this.state = {
      modalAdd: false,
			regionList: [],
      viewAd: false,
      errorMessage: '',
      screen_loader: false,
      region_loader: false,
      screenId: null,
      anchorEl: null,
      regionSearch: '',
      selectedRegion: '',
      formData: {
        regionId: this?.state?.regionList[0]?.id || 0,
        region: this?.state?.regionList[0]?.name || '',
        screenName: '',
        screenID: '',
        screenFirst: '',
        screenLast: '',
        duration: '',
        latitude: '',
        longitude: '',
        price: '',
        interactive: '',
        mediaFormate: '',
        availability: '',
      },
      bottomOffset: 0,
      popoverMarginTop: 0,
    };
  }

  success = (pos: GeolocationPosition) => {
    const crd = pos.coords;
    this.setState((prevData: any) => ({...prevData, formData: {...prevData.formData, latitude: crd.latitude, longitude: crd.longitude}}));
  };

  errors = (err: GeolocationPositionError) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };

	async componentDidMount() {
    super.componentDidMount();
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', this.handleViewportResize);
      window.visualViewport.addEventListener('scroll', this.handleViewportResize);
    }
    const screenId = this.props.navigation.getParam('screenId');
    if(screenId) {
      this.setState({screenId})
    }
		this.getRegionList();
    this.getScreenIdApi();

    if (navigator.geolocation) {
      navigator.permissions
      .query({ name: "geolocation" })
      .then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(this.success, this.errors, options);
        }
      });
    }
	}

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: SS | undefined) {
    if(prevState.regionList !==this.state.regionList){
      this.getScreenData(this.state.screenId);
    }
  }

  async componentWillUnmount() {
    if (window.visualViewport) {
      window.visualViewport.removeEventListener('resize', this.handleViewportResize);
      window.visualViewport.removeEventListener('scroll', this.handleViewportResize);
    }
  }

  handleViewportResize = () => {
    if (window.visualViewport) {
      const offset = window.innerHeight - window.visualViewport.height;
      const offsetTop =  Math.max(window.visualViewport.offsetTop, 0);
      this.setState({ bottomOffset: offset, popoverMarginTop: offsetTop});
    } 
  };

  handleChangeRegion = (newValue: string) => {
    if (newValue === "+ Add new") {
      this.setState({ modalAdd: !this.state.modalAdd });
    }
  };

  handleAddNewDrawer = () => {
    this.setState({ modalAdd: !this.state.modalAdd });
  };

	apiCall = async ({
		method, endPoint, contentType, body, isFormData
	}: any) => {
		const token = await getStorageData("token");
    const header = {
      "Content-Type": contentType,
      token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      isFormData ? body : JSON.stringify(body)
    );

		runEngine.sendMessage(requestMessage.id, requestMessage);

		return requestMessage;
	}

	getRegionList = async () => {
		const GetRegionAPI = await this.apiCall({
			method: configJSON.httpGetMethod, 
			endPoint: configJSON.getRegionApiEndPoint, 
			contentType: "application/json",
		})

		this.getRegionApiID = GetRegionAPI.messageId;
	}

  getScreenData = async (screenId: number) => {
		const GetScreenAPI = await this.apiCall({
			method: configJSON.httpGetMethod, 
			endPoint: `${configJSON.getScreenApiEndPoint}/${screenId}`,
			contentType: "application/json",
		})

		this.getScreenApiID = GetScreenAPI.messageId;
	}

  getScreenIdApi = async () => {
		const GetScreenAPI = await this.apiCall({
			method: configJSON.httpGetMethod, 
			endPoint: `${configJSON.getScreenIdApiEndPoint}`,
			contentType: "application/json",
		})

		this.getScreenIdApiID = GetScreenAPI.messageId;
	}

  handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  };

  createRegion = async (regionName: string) => {
    this.setState({region_loader: true})
		const GetRegionAPI = await this.apiCall({
			method: configJSON.httpPostMethod, 
			endPoint: configJSON.addRegionApiEndPoint, 
			contentType: "application/json",
      body: {
        "name": regionName
      }
		})
		this.addRegionApiID = GetRegionAPI.messageId;
	}

  handleSubmitForm = async (values: ScreenInfo) => {
    this.setState({screen_loader: true});
    console.log("www2",values)
		const UpdatePasswordAPI = await this.apiCall({
			method: this.state.screenId ? configJSON.httpPutMethod : configJSON.httpPostMethod, 
			endPoint: this.state.screenId ? configJSON.updateScreenApiEndPoint : configJSON.addScreenApiEndPoint,
			contentType: "application/json", 
			body: {
        id: this.state.screenId || "",
        screen: {
          screen_name: values.screenName,
          price: values.price,
          region_id: values.regionId,
          media_format: values.mediaFormate,
          width: values.screenFirst,
          height: values.screenLast,
          duration: values.duration,
          latitude: values.latitude,
          longitude: values.longitude,
          screen_number: values.screenID,
          interactive: !!(values.interactive === 'Yes'),
          availability: values.availability,
          host_url: `/displayScreen`,
        },
      }
		})
		this.addScreeCardApiID = UpdatePasswordAPI.messageId;
  };

	goBack = () => {
    if(this.state.screenId) this.props.navigation.goBack();
    else this.props.navigation.navigate('ManagerHelp')
	}

	async receive(from: string, message: Message) { 
		let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
		const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

		if(apiRequestCallId == this.addScreeCardApiID){ 
      this.handleAddScreenResponse(responseJson) 
    }
		if(apiRequestCallId == this.getRegionApiID){ 
      if(responseJson.regions) this.setState({regionList: responseJson.regions}) 
      else this.setState({regionList: []}) 
    }
    if(apiRequestCallId == this.getScreenApiID){ 
      if(responseJson.screen) {
        const screenData = responseJson.screen.data.attributes;
        console.log("wwww",this.state.regionList,screenData)
        const regionId = this.state.regionList.filter((region: regionData) => region.name?.toLowerCase() === screenData.region?.toLowerCase())[0]?.id || 0;
        console.log("www1",regionId)
        this.setState({formData: {
          regionId: regionId || 0,
          region: screenData.region,
          screenName: screenData.screen_name,
          screenID: screenData.screen_number,
          screenFirst: screenData.width,
          screenLast: screenData.height,
          duration: timestampToTime(screenData.duration),
          latitude: screenData.latitude,
          longitude: screenData.longitude,
          price: screenData.price,
          interactive: screenData.interactive ? 'Yes' : 'No',
          mediaFormate: screenData.media_format,
          availability: screenData.availability
        }}) 
      }
    }
		if(apiRequestCallId == this.addRegionApiID){ 
      if(!responseJson.errors) {
        this.setState({region_loader: false, modalAdd: false, errorMessage:responseJson.message, viewAd: true})
        setTimeout(() => {
          this.setState({errorMessage: '', viewAd: false}) 
        }, 1000);
        this.getRegionList();
      }
    }
    if(apiRequestCallId === this.getScreenIdApiID) {
      if(!responseJson.errors) this.setState({formData: {...this.state.formData, screenID: responseJson.screen_number}})
    }
	}

  handleRegionSearch = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      regionSearch: event.target.value
    })
  }

	handleAddScreenResponse = ( response: any) => {
		if(!response.errors || !response) {
      setStorageData('alert', JSON.stringify({
        message: this.state.screenId ? 'Changes successfully updated' : 'New screen successfully created'
      }))
      window.location.replace("/LocalListingAds");
		}
    this.setState({screen_loader: false});
	}

  handleTimeInput = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (keyData: string, value: string) => void) => {
    const inputVal = e.target.value.replace(/\D/g, "");
    let inputNumbersOnly = inputVal.substring(0, 6);  

    let formattedTime = inputNumbersOnly;

    if (formattedTime.length >= 3) {
      formattedTime = `${formattedTime.substring(0, 2)}:${formattedTime.substring(2, 4)}:${formattedTime.substring(4, 6)}`;
    } else if (formattedTime.length >= 2) {
      formattedTime = `${formattedTime.substring(0, 2)}:${formattedTime.substring(2)}`;
    } else if (formattedTime.length >= 1) {
      formattedTime = `${formattedTime}`;
    }

    setFieldValue('duration', formattedTime);
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null
    })
  };

  isSafari () {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes("safari") && !userAgent.includes("chrome")) {
      return true;
    } 
    return false;
  }
	
  render() {
    const { modalAdd } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Box style={{ background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%) !important', fontFamily: "Inter", }}>
          <StyledHeader>
            <Grid container spacing={1} style={{
              margin: '0px -8px 0px',
            }}>
              <Grid xs style={{ padding: '0px 8px', flexGrow: 0 }} item>
                <IconButton data-test-id="backArrowBtn" style={{ paddingLeft: '24px' }} >
                  <ArrowBack style={{ height: '24px', width: '24px', color: "#ffffff", }} data-test-id="go-back" onClick={this.goBack} />
                </IconButton>
              </Grid>
            </Grid>
            <Typography data-test-id="title-header"  style={{ fontSize: '1.9em', paddingBottom: '15px', fontWeight: 'bolder', paddingLeft: '24px', color: '#fff' }}>{this.state.screenId ? "Edit Screen" : "Add New" }</Typography>
          </StyledHeader>
          <div style={styles.container} >
            <Formik
              initialValues={this.state.formData}
              enableReinitialize={true}              
              validationSchema={validationSchema}
              onSubmit={(values: ScreenInfo) => this.handleSubmitForm(values)}
            >
              {({ values, errors, handleChange, handleBlur, setFieldValue }) => (
                <Form style={styles.form}>
                  <InputLabel style={styles.inputFieldTitle}>Region</InputLabel>
                  <StyledRegionButton id="screenName" data-test-id="region-menu" onClick={this.handleMenuOpen}>
                    <Typography style={{ color: "#CBD5E1"}}>{ values.region || "Select region"}</Typography>
                    <KeyboardArrowDownIcon style={{ color: '#94A3B8' }} />
                  </StyledRegionButton>
                  <StyledPopover
                    data-test-id="popover-data"
                    elevation={3}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleMenuClose}
                    popoverMarginTop={this.state.popoverMarginTop}
                    isSafari={this.isSafari()}
                  >
                    <div>
                      <Box className="formControl">
                        <StyledTextField
                          variant="outlined"
                          placeholder={'Search'}
                          value={this.state.regionSearch}
                          onChange={this.handleRegionSearch}
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon className="searchIcon" /></InputAdornment>,
                          }}
                        />
                      </Box>
                      <ul className="regionsList">
                        {
                          this.state.regionList.filter((region: any) => region.name.toLocaleLowerCase().includes(this.state.regionSearch.toLocaleLowerCase())).map((region: any) => {
                            return (
                              <li data-test-id="region_item" key={region.id} className="regionsListItem" onClick={() => {
                                this.handleMenuClose();
                                setFieldValue('regionId', region.id);
                                setFieldValue('region', region.name);
                              }}>
                                {region.name}
                              </li>
                            )
                          })
                        }
                        <li data-test-id="region_item" style={{color: '#1088FF' }} className="regionsListItem" onClick={() => {
                           this.handleChangeRegion('+ Add new');
                        }}>
                         + Add New
                        </li>
                      </ul>
                    </div>
                  </StyledPopover>

                  <ErrorMessage style={{ color: 'red' }} name="region" component="div" />

                  <InputLabel style={styles.inputFieldTitle}>Screen Name</InputLabel>
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    name="screenName"
                    value={values.screenName}
                    type={'text'}
                    id="screenName"
                    style={styles.inputFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage style={{ color: 'red' }} name="screenName" component="div" />

                  <InputLabel style={styles.inputFieldTitle}>Screen ID</InputLabel>
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    disabled
                    name="screenID"
                    value={values.screenID}
                    type={'string'}
                    id="screenID"
                    style={styles.inputFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><span style={{ color: '#94A3B8' }}>#</span></InputAdornment>,
                    }}
                  />
                  <ErrorMessage style={{ color: 'red' }} name="screenID" component="div" />

                  <InputLabel style={styles.inputFieldTitle}>Media Format</InputLabel>
                  <Autocomplete
                    id="availability"
                    fullWidth
                    style={styles.autoCompleteStyle}
                    selectOnFocus={false}
                    options={['image', 'video']}
                    PaperComponent={(props) => <CustomPaperStatic {...props} />}
                    PopperComponent={(props) => <StyledPopper {...props}/>}
                    getOptionLabel={(option) => option}
                    aria-activedescendant=''
                    renderInput={(params) => <MenuIcon><StyledTextField aria-activedescendant='' autoFocus={false} select={false} aria-selected={false} style={{ color: "#CBD5E1", textTransform: 'capitalize',  userSelect: 'none', cursor: 'pointer' }} {...params} 
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true, 
                      style: { cursor: 'pointer', userSelect: 'none' }
                    }}
                    placeholder="Select option" variant="outlined" /></MenuIcon>}
                    popupIcon={<ExpandMore style={{ color: '#94A3B8' }} />}
                    value={values.mediaFormate}
                    onChange={(event: any, newValue: any) => setFieldValue('mediaFormate', newValue)}
                  />

                  <ErrorMessage style={{ color: 'red' }} name="mediaFormate" component="div" />

                  <InputLabel style={styles.inputFieldTitle}>Screen Dimensions</InputLabel>
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={6} style={{ paddingRight: "8px" }}>
                      <StyledTextField
                        variant="outlined"
                        fullWidth
                        name="screenFirst"
                        value={values.screenFirst}
                        placeholder='00'
                        type={'number'}
                        id="screenFirst"
                        style={styles.inputFieldValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ paddingRight: "8px" }}>
                      <StyledTextField
                        variant="outlined"
                        fullWidth
                        name="screenLast"
                        value={values.screenLast}
                        placeholder='00'
                        type={'number'}
                        id="screenLast"
                        style={styles.inputFieldValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                  <ErrorMessage style={{ color: 'red' }} name="screenFirst" component="div" />
                  {!errors.screenFirst && errors.screenLast ? <ErrorMessage style={{ color: 'red' }} name="screenLast" component="div" /> : null}

                  {values.mediaFormate === 'image' && (
                    <>
                      <InputLabel style={styles.inputFieldTitle}>Duration</InputLabel>
                      <StyledTextField
                        variant="outlined"
                        fullWidth
                        name="duration"
                        value={values.duration}
                        placeholder='hh:mm:ss'
                        type={'text'}
                        id="duration"
                        style={styles.inputFieldValue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          this.handleTimeInput(e, setFieldValue)
                          // setFieldValue('duration', 0);
                        }}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage style={{ color: 'red' }} name="duration" component="div" />
                    </>
                  )}

                  <InputLabel style={styles.inputFieldTitle}>Location</InputLabel>
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={6} style={{ paddingRight: "8px" }}>
                      <StyledTextField
                        variant="outlined"
                        fullWidth
                        name="latitude"
                        value={values.latitude}
                        placeholder='latitude'
                        type={'number'}
                        id="latitude"
                        style={styles.inputFieldValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ paddingRight: "8px" }}>
                      <StyledTextField
                        variant="outlined"
                        fullWidth
                        name="longitude"
                        value={values.longitude}
                        placeholder='longitude'
                        type={'number'}
                        id="longitude"
                        style={styles.inputFieldValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                  <ErrorMessage style={{ color: 'red' }} name="latitude" component="div" />

                  <InputLabel style={styles.inputFieldTitle}>	Interactive </InputLabel>
                  <Autocomplete
                    id="combo-box-demo"
                    fullWidth
                    selectOnFocus={false}
                    disabled={this.state.screenId}
                    style={styles.autoCompleteStyle}
                    options={['Yes', 'No']}
                    PaperComponent={(props) => <CustomPaperStatic {...props} />}
                    PopperComponent={(props) => <StyledPopper {...props}/>}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <MenuIcon><StyledTextField style={{ color: "#CBD5E1", opacity: this.state.screenId ? 0.5 : 1}} {...params} 
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true, 
                      style: { cursor: 'pointer', userSelect: 'none' }
                    }}
                    placeholder="Select option" variant="outlined" /></MenuIcon>}
                    popupIcon={<ExpandMore style={{ color: '#94A3B8' }} />}
                    value={values.interactive}
                    onChange={(event: any, newValue: any) => setFieldValue('interactive', newValue)}
                  />
                  <ErrorMessage style={{ color: 'red' }} name="interactive" component="div" />

                  <InputLabel style={styles.inputFieldTitle}>{
                    values.interactive === 'Yes' ? 'Price' : 'Price per day'
                  }</InputLabel>
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    name="price"
                    value={values.price}
                    placeholder='00'
                    type={'number'}
                    id="price"
                    style={styles.inputFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><span style={{color: '#94A3B8' }}>$</span></InputAdornment>,
                    }}
                  />
                  <ErrorMessage style={{ color: 'red' }} name="price" component="div" />

                  <InputLabel style={styles.inputFieldTitle}>	Availability </InputLabel>
                  <Autocomplete
                    id="availability"
                    fullWidth
                    selectOnFocus={false}
                    style={styles.autoCompleteStyle}
                    options={this.state.screenId ? ['Available', 'Pause', 'Remove'] : ['Available', 'Pause']}
                    PaperComponent={(props) => <CustomPaperStatic {...props} />}
                    PopperComponent={(props) => <StyledPopper {...props}/>}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <MenuIcon><StyledTextField style={{ color: "#CBD5E1" }} {...params} 
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true, 
                      style: { cursor: 'pointer', userSelect: 'none' }
                    }}
                    placeholder="Select option" variant="outlined" /></MenuIcon>}
                    popupIcon={<ExpandMore style={{ color: '#94A3B8' }} />}
                    value={values.availability}
                    onChange={(event: any, newValue: any) => setFieldValue('availability', newValue)}
                  />
                  <ErrorMessage style={{ color: 'red' }} name="availability" component="div" />

                  <Box style={{marginBottom: '50px'}} />
                  <Box
                    sx={{
                      position: 'fixed',
                      boxSizing: 'border-box',
                    }}
                    style={styles.buttonArea}
                  >
                    <Button
                      fullWidth
                      variant="text"
                      className={'cancelBtn'}
                      style={{
                        marginBottom: 8,
                        color: "#ffffff",
                        background: "#000"
                      }}
                      onClick={this.goBack}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      fullWidth
                      disabled={this.state.screen_loader}
                      variant="contained"
                      style={{
                        color: "black",
                        marginBottom: 8,
                        background: "linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)"
                      }}
                    >
                      {"Save"}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        </Box>
        <Drawer
          anchor="bottom"
          open={modalAdd}
          onClose={this.handleAddNewDrawer}
          PaperProps={{ elevation: 0, style: { backgroundColor: 'black', padding: '0 24px 15px', borderTopRightRadius: '24px', marginBottom: this.isSafari() ? 0 : this.state.bottomOffset } }}
        >
          <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Box style={{ display: 'flex', justifyContent: 'end' }}>
              <IconButton style={{ color: '#000' }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <div style={{ flex: '1' }}>
              <Typography variant="body1" style={styles.enterCardDetails}>
                Add new region
              </Typography>
            </div>
            <Box style={{ display: 'flex', justifyContent: 'end' }}>
              <IconButton style={{ color: '#FFF', padding: '0px' }} onClick={this.handleAddNewDrawer}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Container maxWidth='md' style={{
            width: "100%",
            display: "block",
            height: "auto",
            background: "black",
            boxSizing: "border-box",
          }}>
            <Formik
              initialValues={{ screen_region_name: '' }}
              onSubmit={(values) => {
                this.createRegion(values.screen_region_name);
              }}
              validationSchema={Yup.object({
                screen_region_name: Yup.string()
                  .required("Region name is required")
                  .matches(/^[a-zA-Z0-9 ]+$/, 'Region name cannot contain special characters')
                  .min(3, 'Screen name must be at least 3 character')
                  .max(15, 'Screen name must be at most 15 characters')
                  .test(
                    'uniqueRegion',
                    'This region already exists',
                    (value) => !this.state.regionList?.some((item: any) => item.name === value)
                  ),
              })}
            >
              {({ values, handleChange, handleBlur }) => (
                <Form style={styles.regionForm}>
                  <InputLabel style={styles.inputFieldTitle}>Name</InputLabel>
                  <StyledTextField
                    fullWidth
                    variant="outlined"
                    name="screen_region_name"
                    value={values.screen_region_name}
                    type={'text'}
                    id="screen_region_name"
                    style={styles.inputFieldModelValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='Enter region name'
                  />
                  <ErrorMessage style={{ color: 'red' }} name="screen_region_name" component="div" />

                  <ButtonWrapper>
                    <SaveButton
                      type='submit'
                      style={{ marginTop: '20px', textTransform: 'none' }}
                      disabled={!values.screen_region_name || this.state.region_loader}
                    >
                      Save
                    </SaveButton>
                  </ButtonWrapper>
                </Form>
              )}
            </Formik>
          </Container>
        </Drawer>
        {this.state.viewAd &&
          <Box style={styles.successStatusWrapper} data-test-id="card_success">
            <Box style={{ ...styles.successStatus, position: 'fixed' }}>
              <img src={success_icon} /> {this.state?.errorMessage || ''}
            </Box>
          </Box>
        }
      </ThemeProvider>
    );
  }
}

export default AddNewScreen;

const StyledHeader = styled(Box)({
  position: 'sticky',
  top: 0,
  boxShadow: "none",
  zIndex: 999,
  paddingBottom: '0px',
  color: "#FFF",
  borderBottom: "1px solid #64748B",
  background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'
});

const CustomPaperStatic = styled('ul')({
  marginTop: '0px',
  backgroundColor: "#000",
  color: "white",
  padding: '8px',
  borderRadius: '8px',
  '& .MuiAutocomplete-option': {
    paddingLeft: '12px',
  },
  '& .MuiAutocomplete-option:hover': {
    backgroundColor: '#1B1B1BCC'
  }
})

const StyledPopover = styled(PopoverWrapper)(({ popoverMarginTop, isSafari}: StyledPopoverProps) => ({
  "& .MuiPopover-paper":{
    maxHeight: "30%",
    width: "100%",
    borderRadius: "8px",
    maxWidth: "calc(100% - 44px)",
    left: "23px !important",
    marginTop: `${isSafari ? popoverMarginTop : 0}px !important`
  },
  "& .formControl":{
    padding: "12px 16px",
    background: "#000",

    "& .MuiTextField-root":{
      borderRadius: "24px",
      border: '1px solid #475569 !important'
    },
    "& .MuiOutlinedInput-root":{
      height: "40px",
      borderRadius: "24px",
      "&:hover .MuiOutlinedInput-notchedOutline":{
        borderColor: "#94A3B8"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#94A3B8"
      },
      
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline":{
      borderWidth: "1px"
    },
  },
  "& .regionsList":{
    padding: 0,
    display: "flex",
    flexDirection: "column",
    listStyle: "none",
    marginTop: 0,
    marginBottom: 0,
    background: "#000",
  },
  "& .regionsListItem":{
    height: "48px",
    padding: "12px 16px",
    background: "#1B1B1BCC",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#FFF",
    boxSizing: "border-box"
  },
  "& .regionsListItem:hover":{
    background: "#010101CC",
    color: "#FFF",
  }
}))

const StyledRegionButton = styled(Box)({
  padding: "8px 14px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  border: "1px solid rgb(100, 116, 139)",
  height: "48px",
  boxSizing: "border-box",
  color: "#94A3B8",
  "& .MuiSvgIcon-root":{
    fontSize: "24px"
  }
});

const StyledTextField = styled(TextField)({
  borderRadius: "8px",
  width: "100%",
  borderColor: "transparent",
  "& .MuiOutlinedInput-input": {
    color: "#CBD5E1",
    borderColor: "transparent"
  },
  "&.MuiOutlinedInput-notchedOutline":{
    border: "none"
  },
  "&:hover .MuiOutlinedInput-notchedOutline":{
    borderColor: "transparent"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent"
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline":{
    borderWidth: "0px"
  },
  "& .searchIcon":{
    color: "#94A3B8"
  },
  "& .MuiOutlinedInput-input::placeholder":{
    color: "#94A3B8",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    opacity:1
  },
  "& MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl MuiInputBase-adornedStart MuiOutlinedInput-adornedStart": {
    border: "1px solid",
    borderImageSource: "linear-gradient(175.74deg, #FFFFFF 3.31%, #999999 96.39%)",
  },
	'& .MuiOutlinedInput-input input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': { 
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    'appearance': 'none',
    'margin': 0,
	},
	'& input[type=number]': {
    '-moz-appearance': 'textfield'
	},
  '& .MuiAutocomplete-clearIndicator': {
  display: 'none'
}

});

const SaveButton = styled(Button)({
  padding: '16px 109px 16px 108px',
  background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)',
  gap: '8px',
  borderRadius: '8px',
  display: 'flex',
  bottom: '15px',
  margin: 'auto',
  fontWeight: 700,
  fontSize: '16px',
  width: '100%',
  textTransform: "capitalize",
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 0',
  width: '100%',
  textTransform: 'none',
});

const MenuIcon = styled(Box)({
  '& .MuiButtonBase-root': {
    color: 'white'
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input' :{
    color:'white'
  }
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: 'white',
  '&.add-new': {
    color: '#1088FF',
    fontWeight: 400,
  },
}));